@import 'mixins';

.navbar {
    box-shadow: $container-shadow;
}

.card {
    box-shadow: $container-shadow;
}

.popover {
    @include minimalScrollbar;
    box-shadow: $small-box-shadow;
    margin-top: $spacer/2;
    max-height: 60vh;
    overflow: auto;

    &.wide {
        max-width: $popover-wide-max-width;
    }

    .btn-toolbar {
        justify-content: flex-end;
        margin-top: 2 * $spacer;

        .btn:not(:last-child) {
            margin-right: $spacer;
        }
    }
}

.custom-checkbox:not(:last-child) {
    margin-bottom: $spacer/4;
}
.custom-control-label {
    padding-top: $form-check-input-margin-y;
}

.popover.wide {
    max-width: $popover-wide-max-width;
}

.badge-secondary {
    background-color: $light-gray;
}

// Dropdown
.dropdown-menu {
    @include minimalScrollbar;
    box-shadow: $small-box-shadow;
    margin-top: $spacer/2;
    max-height: 60vh;
    overflow-x: hidden;
}

// Progress Bar

.progress {
    box-shadow: unset;
}

.progress-bar {
    border-radius: 2rem;
}

.progress-bar.bg-danger {
    background-color: #e68790 !important;
}

// Buttons

// Primary button
.btn-primary,
.btn-primary:focus,
.btn-primary:not(:disabled):not(.disabled):active {
    @include button(
        white,
        $vividi-primary,
        white,
        $vividi-primary-dark,
        $font-size-smaller,
        $shadow: $button-shadow-light
    );
}

.btn-primary:disabled,
.btn-primary:disabled:hover {
    background: $vividi-primary-light;
}

// Secondary button
.btn-secondary,
.btn-secondary:focus,
.btn-secondary:not(:disabled):not(.disabled):active,
.show > .btn-secondary.dropdown-toggle {
    @include secondaryButton;
}

.btn-secondary:disabled,
.btn-secondary:disabled:hover {
    color: rgba(0, 0, 0, 0.7);
    background: $grey90;
}

.btn-disabled,
.btn-disabled:focus,
.btn-disabled:active {
    @include button-variant($grey90, $grey90, $grey90, $grey90, $grey90, $grey90);
    color: rgba(0, 0, 0, 0.7) !important;
    opacity: 0.65;
}

// Button group
.btn-group > .btn {
    outline: none;
    box-shadow: none;
}

.btn-group > .btn:not(:first-child) {
    outline: none;
    margin-left: unset;
}

// Delete / Danger button
.btn-danger,
.btn-danger:focus,
.btn-danger:not(:disabled):not(.disabled):active {
    @include button(black, $grey90, $negativeTrend, $button-negative-bg);
}

.btn-danger:disabled,
.btn-danger:disabled:hover {
    color: black;
    background: $grey90;
}

// Link button
.btn-link,
.btn-link:focus,
.btn-link:not(:disabled):not(.disabled):active {
    @include button($button-link-grey, none, $vividi-primary-dark, none);
    padding: 0;
}

.btn-link:disabled,
.btn-link:disabled:hover {
    color: $button-link-disabled-grey;
    background: none;
}

.btn-link.danger:hover {
    color: $negativeTrend;
}

.dropdown-header {
    background: $white-smoke;
    padding: ($spacer / 2) (0.75 * $spacer);

    & + .dropdown-header {
        margin-top: $spacer / 2;
    }
}

// Tabs
.nav-tabs .nav-link {
    color: $darker;
    background-color: transparent;
    border: 0px solid transparent;
    font-weight: 500;
    padding: 0.6rem 1.5rem;
    font-size: $font-size-smaller;
    white-space: nowrap;

    &.active {
        border-bottom: 2px solid $vividi-primary;
        border-color: $vividi-primary !important;
        background-color: transparent;

        div {
            &:after {
                background-color: $nav-tabs-link-active-color;
            }
        }
    }

    div {
        &:before {
            background-color: $light;
        }

        &:after {
            background-color: $darker;
        }
    }
}

// Typeahead
// TODO - should not be necessary with the next release of react-bootstrap-typeahead
.rbt-input-multi.focus,
.rbt-input.focus {
    box-shadow: $input-focus-box-shadow !important;
    border-color: $input-focus-border-color !important;
}

// Reduce height of react typeahead
.rbt-input-multi.form-control {
    max-height: $max-typeahead-height;
    overflow-y: auto;
    @include minimalScrollbar;
}

.rbt-token {
    color: $vividi-primary-dark !important;
    background-color: $rbt-token-bg !important;
    margin: 1px 8px 5px 0 !important;
    font-size: $font-size-smaller !important;

    &.rbt-token-disabled {
        background-color: rgba(0, 0, 0, 0.1) !important;
        color: #495057 !important;
        pointer-events: none !important;
    }

    a {
        text-decoration: underline;
    }
}

.rbt-token-removeable {
    padding-right: $spacer * 1.8 !important;
}
.pagination {
    justify-content: flex-end;
}

// Date range picker
.daterangepicker {
    // Hide selected date range text in date range picker
    .drp-selected {
        display: none !important;
    }

    font-family: $font-family-lato;
    font-size: $font-size-small;

    .drp-buttons {
        position: absolute;
        right: 0;
        bottom: 0;
        border: none;
    }

    ul {
        color: $vividi-primary-dark;
    }
    .calendar-table .in-range {
        background-color: $palette-grey;
    }

    .ranges li.active,
    .calendar-table .start-date,
    .calendar-table .end-date {
        background-color: $vividi-primary;
    }

    .calendar-table .start-date,
    .calendar-table .end-date {
        &:hover {
            background-color: $vividi-primary-dark;
        }
    }

    @include media-breakpoint-down(sm) {
        .calendar-time {
            text-align: left;
        }
    }
}

// Bootstrap pagination
.pagination .page-item.active .page-link {
    z-index: auto;
}

.modal-content {
    .form-group:last-child {
        margin-bottom: 0;
    }

    img {
        max-height: 85vh;
    }
}

.modal-footer {
    button {
        white-space: unset;
    }
}

// Dark reader
.react-switch-github__dark {
    .react-switch-bg {
        border-color: $vividi-primary-dark !important;
    }
    .react-switch-handle {
        background: $vividi-primary-dark !important;
    }
    .react-switch-icon {
        svg {
            fill: white !important;
        }
    }
}
