@import '../../variables';

.heatmapsContainer {
    display: flex;
    flex-wrap: wrap;
}

.heatmapBlock {
    margin: 10px;
    margin-bottom: 30px;
    height: 620px;
    width: 780px;
    img {
        height: 580px;
    }
    a {
        margin-left: 10px;
    }
}
