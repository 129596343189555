@import '../../../mixins.scss';

.modal {
    & > div {
        @include media-breakpoint-up(md) {
            max-width: map-get($container-max-widths, 'xl');
        }

        @include media-breakpoint-down(sm) {
            max-width: 100%;
        }
    }
}
