@import '../../../mixins';

.localityTable {
    margin-bottom: 0;
    thead tr {
        border-bottom: 2px solid $vividi-primary;
    }

    thead tr th {
        border: none;
        color: $darker;
        font-size: 1rem;
        font-weight: 400;
        white-space: nowrap;
        padding: 0 0.5rem 0.5rem 0;
    }

    tbody tr {
        border-top: $border-width solid $vividi-primary;
    }

    tbody tr td {
        border: none;
        padding: 0.8rem 0.8rem 0.8rem 0;
        font-size: 1rem;
        vertical-align: inherit;
    }

    .name {
        color: $black;
        white-space: nowrap;
    }

    .dataValue {
        color: $black;
        font-weight: 400;
        flex: 1;
    }

    .screenText {
        @include hideOnSmallerThan;
    }

    .dataValueAligned {
        @extend .dataValue;
        text-align: left;
    }

    .dataValue + span {
        flex: 2;
    }
}

.currentOccupancyText {
    font-size: 1.4rem;
    margin-left: $spacer;
    margin-right: $spacer;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    min-width: 3rem;
}
