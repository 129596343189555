@import '../../mixins';

.intervalPicker {
    padding: $spacer;
}

.intervalPickerButton {
    box-shadow: unset;
}

.popover {
    max-width: 100%;
}

.searchInput {
    max-width: 20rem;
    padding: 0 0 1.5rem 0;
}

.connectionValue,
.generationValue {
    text-align: center !important;
}

table {
    @include media-breakpoint-down(md) {
        .generationHeader,
        .generationValue,
        .ownerHeader,
        .ownerValue {
            display: none !important;
        }
    }

    @include media-breakpoint-down(sm) {
        .devicesHeader,
        .devicesValue {
            display: none !important;
        }
    }
}

.link {
    @include link;
}

.deviceLink {
    @include link;
    color: unset;
}

.deviceLabelContainer {
    &:hover {
        cursor: pointer;
        background: $grey;
    }
}

.longRangeRow {
    background: $longRange;
}
