@import '../../../mixins.scss';

.dataChange {
    padding-left: $spacer;
    font-size: $font-size-base;
    font-weight: 400;
    @include flexContainer($justifyContent: center);
    flex-wrap: nowrap;

    &.bigger {
        font-size: $font-size-larger;
        padding-left: 0;
        @include media-breakpoint-up(lg) {
            padding-left: $spacer * 1.5;
        }
    }

    .dataChangeNumeric {
        min-width: 2rem;
        display: flex;
        justify-content: flex-end;
    }
    .dataChangeDurationString {
        min-width: 4.5rem;
        display: flex;
        justify-content: flex-end;
    }
}

.positive {
    color: $positiveTrend;
}

.negative {
    color: $negativeTrend;
}

.smallIcon {
    width: 0.25rem;
    height: 0.25rem;
    vertical-align: middle;
}

.overlayContainer {
    cursor: pointer;
}
