@import '../mixins';

.snapshotGridColumn {
    padding-top: $spacer * 0.75;
    padding-bottom: $spacer * 0.75;
}

.snapshotHeaderContainer {
    @include flexContainer;
    padding-bottom: $spacer * 0.5;
}

.errorContainer {
    @extend .loadingContainer;
    font-size: $font-size-data-display-box;
    color: $darker;
    flex-direction: column;
    background: $grey90;
    border-radius: $card-border-radius;

    svg {
        margin-bottom: $spacer;
        font-size: $font-size-statistic-box;
    }
}
