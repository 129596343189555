@import '../../mixins.scss';

.liveView {
    text-align: center;
    width: auto;
    height: $staySafe-live-view-height;
}

.intensityImageContainer {
    @include flexContainer($justifyContent: center);
    margin-top: $spacer * 2;
    margin-bottom: $spacer * 2;
}

.legend {
    @include flexContainer($justifyContent: center);
    gap: $spacer;
    .labelText {
        margin-left: $spacer;
    }
}

.liveViewGridCard {
    margin-bottom: $spacer * 3;
}

.alertContainer {
    @include flexContainer($justifyContent: flex-start);
    margin-bottom: $spacer * 2;
    svg {
        margin-right: $spacer;
    }
}
