$weight-light: 300;
$weight-normal: 400;
$weight-semibold: 500;
$weight-bold: 700;
$weight-black: 900;

/* Lato */
$font-family-lato: 'Lato';

/* Webfont: Lato-Bold */
@font-face {
    font-family: $font-family-lato;
    src: url('./fonts/Lato-Bold.eot'); /* IE9 Compat Modes */
    src: url('./fonts/Lato-Bold.eot?#iefix') format('embedded-opentype'), url('./fonts/Lato-Bold.woff2') format('woff2'),
        url('./fonts/Lato-Bold.woff') format('woff'), url('./fonts/Lato-Bold.ttf') format('truetype');
    font-style: normal;
    font-weight: $weight-semibold;
}

/* Webfont: Lato-Light */
@font-face {
    font-family: $font-family-lato;
    src: url('./fonts/Lato-Light.eot'); /* IE9 Compat Modes */
    src: url('./fonts/Lato-Light.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Lato-Light.woff2') format('woff2'), url('./fonts/Lato-Light.woff') format('woff'),
        url('./fonts/Lato-Light.ttf') format('truetype');
    font-style: normal;
    font-weight: $weight-light;
}

/* Webfont: Lato-Regular */
@font-face {
    font-family: $font-family-lato;
    src: url('./fonts/Lato-Regular.eot'); /* IE9 Compat Modes */
    src: url('./fonts/Lato-Regular.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Lato-Regular.woff2') format('woff2'), url('./fonts/Lato-Regular.woff') format('woff'),
        url('./fonts/Lato-Regular.ttf') format('truetype');
    font-style: normal;
    font-weight: $weight-normal;
}
