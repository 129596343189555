@import '../mixins';

.breadcrumbContainer {
    padding: unset;
    > ol {
        padding: 0.75rem 0;
        background-color: unset;
        margin-bottom: 0;

        > li {
            text-decoration: underline;
        }
        > li:last-child {
            text-decoration: none;
        }
    }
}
