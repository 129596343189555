@import '../mixins.scss';

.logo {
    max-height: 5rem;
}
.errorContainer {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 5rem;
    flex-direction: column;
}

.title {
    font-family: $font-family-sans-serif;
    font-size: 3rem;
    color: $info;
    padding-top: 2rem;
}

.message {
    font-family: $font-family-sans-serif;
    font-size: 1.5rem;
    padding-top: 2rem;

    .link {
        @include link;
    }
}

.goBackButton {
    margin-top: 2rem;
    padding: 0.75rem 2rem;
    background-color: $vividi-primary;
    color: $white;
    border: $border-width solid $vividi-primary;
    border-radius: 0.75rem;
    font-size: 1.5rem;

    &:hover {
        border: $border-width solid $vividi-primary;
        background-color: $white;
        color: $vividi-primary;
    }
}
