@import '../../variables';

.editorImage {
    display: flex;
    align-items: center;
    justify-content: center;
}

.editor {
    display: grid;
    overflow: auto;

    div {
        grid-row: 1;
        grid-column: 1;
    }
}

.zone {
    fill: $vividi-primary;
    fill-opacity: 0.2;

    &:hover {
        cursor: pointer;
    }
}

.zoneInactive {
    fill: $light-gray;
    fill-opacity: 0.2;

    &:hover {
        cursor: pointer;
        fill: $vividi-primary;
        fill-opacity: 0.2;
    }
}

.zoneDragHandle {
    fill: $vividi-primary;

    &:hover {
        fill: $vividi-primary;
        cursor: move;
    }
}

.zoneEdge {
    stroke: $vividi-primary;
    stroke-width: 2;

    &:hover {
        stroke: $vividi-primary;
        cursor: pointer;
    }
}
.zoneEdgeInactive {
    stroke: $light-gray;
    stroke-width: 2;
}

.activeZone {
    .zone {
        fill: $white;
    }

    &.boundary {
        .zoneDragHandle {
            fill: $yellow;
        }

        .lineNegative,
        .linePositive {
            stroke: $yellow;
        }
    }
}

.svgText {
    cursor: pointer;
}
