@import '../../variables';
@import '../../mixins';

.logo {
    width: 170px;
    height: 80px;
    margin: -5px 0;
}

.mainNavbar {
    min-height: $navbar-height;
    background: $white;
    z-index: $zindex-sticky + 1;
    font-size: $font-size-base;
    text-transform: uppercase;
    color: $info;
    padding: 0 $spacer * 2;

    @include media-breakpoint-up(lg) {
        padding-right: 0;
    }

    :global .nav-item {
        padding: $nav-item-padding-y $nav-item-padding-x !important;
    }

    :global .nav-link {
        color: $info !important;
        @include hover-focus() {
            color: $vividi-primary !important;
        }
    }

    .highlightedButton {
        font-size: $font-size-larger;
        color: $white !important;
        padding: 3px 25px !important;
        background-color: $vividi-primary;
        border: 2px solid $vividi-primary;
        border-radius: 5px;
        transition: $normal-transition;

        &:hover {
            background-color: $white;
            border: 2px solid $vividi-primary;
            color: $vividi-primary;
        }
    }

    .highlightedButtonInverse {
        font-size: $font-size-larger;
        color: $vividi-primary !important;
        padding: 3px 25px !important;
        background-color: $white;
        border: 2px solid $vividi-primary;
        border-radius: 5px;
        transition: $normal-transition;

        &:hover {
            background-color: $vividi-primary;
            border: 2px solid $vividi-primary;
            color: $white !important;
        }
    }

    :global .btn.active {
        color: $white !important;
        background-color: $vividi-primary !important;
        border: 2px solid $vividi-primary !important;
    }

    .navItemContainer {
        align-self: center;
    }
}

.narrow {
    @include media-breakpoint-up(lg) {
        padding: 0 100px 0 120px;
    }
}

.fullscreenNavbar {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 1rem;

    @include media-breakpoint-down(md) {
        min-height: 100vh;
        display: inline-block;
    }
}

.square {
    display: none;

    @include media-breakpoint-down(md) {
        margin-bottom: 3px;
        margin-right: 10px;
        height: 5px;
        width: 5px;
        background-color: $vividi-primary;
        display: inline-block;
    }
}

.dropdownToggle {
    > a {
        display: grid;
        grid-template-columns: repeat(3, auto);
        grid-column-gap: $spacer * 1.5;
        width: fit-content;
        margin-right: $spacer;

        .avatar {
            font-size: 1.5rem;
            grid-column: 1;
            grid-row: 1 / 3;
            align-self: center;
        }

        .email {
            grid-column: 2;
            grid-row: 1;
        }

        .role {
            color: $vividi-primary-dark !important;
            grid-column: 2;
            grid-row: 2;
            text-transform: initial;
            font-weight: 500;
        }

        .caret {
            grid-column: 3;
            grid-row: 1 / 3;
            align-self: center;
        }
    }

    a::after {
        display: none;
    }

    > a * {
        color: $info !important;
    }

    > div {
        right: $nav-item-padding-x;
        left: auto;
    }
}
