@import '../../mixins.scss';

.box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding-top: 1.5rem;

    @include media-breakpoint-up(lg) {
        padding-top: 0;
    }
}

.label {
    font-size: $font-size-smaller;
    color: $darker;
    text-align: center;
    line-height: 1.5rem;
    white-space: nowrap;

    @include media-breakpoint-up(lg) {
        font-size: 1rem;
        line-height: inherit;
    }
}
.genderStatisticsBox {
    .data {
        font-size: 3rem;
    }
}

.icon {
    font-size: 2rem;
    padding-left: $spacer;
    min-width: 3rem;
}

.iconGender {
    @extend .icon;
    margin-right: $spacer;
    color: $darker;
}

.dataDisplay {
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
    align-items: center;
    line-height: 3rem;
    width: 100%;
    flex-flow: column;

    @include media-breakpoint-up(lg) {
        line-height: 6rem;
        flex-flow: row;
    }
}

.multipleDataDisplay {
    @extend .dataDisplay;
    flex-flow: row;
    line-height: 6rem;
}

.data {
    padding-left: 0;
    color: $vividi-primary;
    font-weight: 500;
    display: flex;
    align-items: center;
    white-space: nowrap;

    @include media-breakpoint-up(lg) {
        padding-left: 1rem;
        padding-left: 0.7rem;
    }
}

.dataSizeLarge {
    font-size: $font-size-statistic-box;

    &.boxGender {
        font-size: $font-size-statistic-box-small;
    }

    @include media-breakpoint-up(lg) {
        font-size: $font-size-statistic-box-large;
    }
}

.dataSize {
    font-size: 2rem;
}

.changeContainer {
    display: flex;
    align-items: center;
    align-self: flex-end;
    line-height: $spacer * 2;
}

.popover {
    padding: $font-size-small;
}

// Queue detail related styles

.wrapperHorizontal {
    display: flex;
    flex-wrap: nowrap;
    flex-flow: row;
}

.wrapperVertical {
    display: flex;
    flex-wrap: nowrap;
    flex-flow: column;
}

.verticalBoxItem {
    line-height: unset;
}

.rightBoxCol,
.leftBoxCol {
    white-space: nowrap;
    .box {
        padding-top: 0;
    }
    .dataDisplay {
        flex-flow: row;
    }
}

.rightBoxCol {
    border-right: 2px solid lightgray;
    .label {
        @include flexContainer($justifyContent: flex-end);
    }

    .dataDisplay {
        justify-content: flex-end;
    }
}

.leftBoxCol {
    .label {
        @include flexContainer($justifyContent: flex-start);
    }
    .dataDisplay {
        justify-content: flex-start;
        .data {
            padding-left: 0px;
        }
    }
}

.queueHighlight {
    .dataDisplay {
        .data {
            color: $queueDetailsWaitTime;
        }
    }
}
.serveHighlight {
    .dataDisplay {
        .data {
            color: $queueDetailsServeTime;
        }
    }
}
.salesHighlight {
    .dataDisplay {
        .data {
            color: $darker;
        }
    }
}
.queueFlowImage {
    @include hideOnSmallerThan(sm);
    display: flex;
    margin: 0 auto;
    width: 85%;
}
