@import '../../mixins';

.title {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-weight: $display4-weight;
    }

    margin-bottom: 2 * $spacer;

    .subtitle {
        font-size: $font-size-base;
        font-weight: normal;
    }
}

.titleWithButtons {
    @include flexContainer();
    white-space: nowrap;
    flex-wrap: wrap;
}
