@import '../../mixins';

.headingContainer {
    display: grid;
    grid-template-columns: auto 1fr auto;
    grid-template-areas: 'headingColumn searchColumn buttonColumn';
    gap: $spacer * 2;
    margin-bottom: $spacer * 1.5;
    align-items: center;

    @include media-breakpoint-down(md) {
        padding: $spacer;
        grid-template-columns: 1fr 1fr;
        grid-template-areas:
            ' headingColumn buttonColumn '
            ' searchColumn searchColumn ';
    }

    .heading {
        color: $vividi-primary-dark;
        grid-area: headingColumn;
        margin-bottom: 0;
    }

    .search {
        max-width: 300px;
        grid-area: searchColumn;
        @include media-breakpoint-down(md) {
            max-width: unset;
        }
    }

    .button {
        grid-area: buttonColumn;
        @include media-breakpoint-down(md) {
            justify-self: end;
        }
    }
}
