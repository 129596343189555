@import '../mixins';

.histogramScrollWrapper {
    @include flexContainer($alignItems: flex-start);
}

.histogramContainer {
    flex-grow: 1;
    display: grid;
    grid-gap: 4px;
    grid-template-columns: #{minmax(auto, 180px) repeat(var(--ColumnCount), var(--ColumnSize, 40px))};
    grid-auto-rows: minmax(40px, auto);
    overflow: auto;

    .rowTitle {
        @include flexContainer();
    }

    .histogramSegment {
        border-radius: $border-radius;
        font-size: $font-size-small;
        text-align: center;
        button {
            box-shadow: none;
            width: 100%;
            border: 0;
            padding: 0 0 100%;
        }
    }

    .histogramSegmentNull {
        @include histogramSegment($histogram-2d-nullValue);
    }
    .histogramSegmentLow {
        @include histogramSegment($histogram-2d-lowValue);
    }
    .histogramSegmentMidLow {
        @include histogramSegment($histogram-2d-midLowValue);
    }
    .histogramSegmentMid {
        @include histogramSegment($histogram-2d-midValue);
    }
    .histogramSegmentMidHigh {
        @include histogramSegment($histogram-2d-midHighValue);
    }
    .histogramSegmentHigh {
        @include histogramSegment($histogram-2d-highValue);
    }
}

.histogramLegendContainer {
    @include flexContainer($justifyContent: space-between);
    margin-top: $spacer * 1.5;
    .histogramSegmentNull {
        margin-right: $spacer;
        color: $histogram-2d-nullValue;
    }
    .histogramSegmentLow {
        margin-left: $spacer;
        margin-right: $spacer;
        color: $histogram-2d-lowValue;
    }
    .histogramSegmentMidLow {
        margin-left: $spacer;
        margin-right: $spacer;
        color: $histogram-2d-midLowValue;
    }
    .histogramSegmentMid {
        margin-left: $spacer;
        margin-right: $spacer;
        color: $histogram-2d-midValue;
    }
    .histogramSegmentMidHigh {
        margin-left: $spacer;
        margin-right: $spacer;
        color: $histogram-2d-midHighValue;
    }
    .histogramSegmentHigh {
        margin-left: $spacer;
        margin-right: $spacer;
        color: $histogram-2d-highValue;
    }
}
