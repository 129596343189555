@import '../../mixins';

.devicesGridColumn {
    padding: $spacer;

    :global .list-group {
        @include minimalScrollbar;
        max-height: $map-devices-container-height;
        overflow: auto;
    }
}

.buttonGroup {
    display: flex;
    gap: $spacer;
}

.buttonGroupRight {
    display: flex;
    justify-content: flex-end;
    gap: $spacer;
}

.devicesGridColumnCentered {
    @include flexContainer($justifyContent: center);
}
