@import '../../mixins';

.genderAgeBreakdown {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    grid-template-rows: min-content minmax($gender-age-plot-min-height, 1fr) min-content;

    :global .card-title {
        grid-row: 1;
        grid-column: 1;
    }

    .genderAgePlot {
        grid-row: 2;
        grid-column: 1;
    }

    .genderAgeLegend {
        grid-row: 3;
        grid-column: 1;
        @include flexContainer($justifyContent: center);
        gap: $spacer;
        margin-top: $spacer;

        .labelText {
            margin-left: $spacer / 2;
        }
    }
}

.plot {
    @include plot();
}

.plotControlsLabel {
    margin-right: $spacer;
}

.plotControlButton {
    cursor: pointer;
    color: $grey90;
    border: none;
    box-shadow: none;
    padding: $spacer * 0.2;
    margin: $spacer * 0.3;
    font-size: 1rem;
    background: none;
    border-radius: 0%;
    border-bottom: 2px solid white;
    text-align: center;
    &:hover {
        color: black;
        background: white;
        border-bottom: 2px solid $vividi-primary;
    }
}

.plotControlButtonDark {
    @extend .plotControlButton;
    &:hover {
        color: black;
        border-bottom: 2px solid $vividi-primary-dark;
    }
}

.plotControlButtonActive {
    @extend .plotControlButton;
    color: black;
    border-bottom: 2px solid $vividi-primary;
}

.plotControlButtonActiveDark {
    @extend .plotControlButtonActive;
    border-bottom: 2px solid $vividi-primary-dark;
}

.heatmapCard {
    height: $heatmap-carousel-height !important;
}
