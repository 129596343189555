.noRightPadding {
    padding-right: 0;
}

.timeRangeWrapper {
    display: flex;
    align-items: center;
    padding-top: 5px;
    justify-content: space-around;
    flex-wrap: nowrap;
}

.sliderWrapper {
    display: flex;
    justify-content: center;
    padding: 5px;
}

.addRangeBadge {
    align-self: center;
    cursor: pointer;
    padding: 5px;
}

.center {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
}

.timeIntervalsTable {
    padding: 1.25rem 0;
}

.dayPickerBackground {
    background-color: #ffffff;
}

//**----------------------.-----*\\
// range-slider components styles \\
//**------------------------------*\\

.sliderWrapper {
    height: 5rem;
    width: 100%;
    padding: 0;
}

.slider {
    margin: 2.5rem;
    position: relative;
    width: 90%;
}

.rail {
    position: absolute;
    width: 100%;
    height: 8px;
    border-radius: 1px;
    cursor: pointer;
    background-color: #292b2c;
}

.tickMarker {
    position: absolute;
    margin-top: 8px;
    width: 1px;
    height: 5px;
    background-color: transparent;
}

.tickLabel {
    position: absolute;
    margin-top: 22px;
    font-size: 10px;
    text-align: center;
}

.handle {
    position: absolute;
    margin-left: -11px;
    margin-top: -7px;
    z-index: 2;
    width: 20px;
    height: 20px;
    cursor: pointer;
    border-radius: 50%;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.2);
    background-color: #5bc0de;
}

.track {
    position: absolute;
    height: 8px;
    z-index: 1;
    background-color: #3d8af9;
    cursor: pointer;
}
