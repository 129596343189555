@import '../../mixins';

.plotControlButton {
    cursor: pointer;
    color: $grey90;
    border: none;
    box-shadow: none;
    padding: $spacer * 0.2;
    margin: $spacer * 0.3;
    font-size: 1rem;
    background: none;
    border-radius: 0%;
    border-bottom: 2px solid white;
    text-align: center;
    outline: none;
    white-space: nowrap;
    transition: $normal-transition;

    &:hover {
        color: black;
        outline: none;
        background: white;
        border-bottom: 2px solid var(--UnderlineColor, #{$vividi-primary});
    }
    &:focus {
        outline: none;
    }
}

.plotControlButtonActive {
    @extend .plotControlButton;
    color: black;
    border-bottom: 2px solid var(--UnderlineColor, #{$vividi-primary});
}
