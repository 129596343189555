@import '../../mixins.scss';

.titleContainer {
    @include flexContainer;

    .buttonsContainer {
        display: flex;
        flex-direction: row;
        align-items: center;

        div:last-child {
            margin-right: 0;
        }

        @include media-breakpoint-down(md) {
            flex: 1;
            margin-top: $spacer;
            justify-content: flex-end;
        }
    }

    @include media-breakpoint-down(md) {
        flex-direction: column;
    }
}

.heading {
    padding-top: $card-spacer-y;
    padding-bottom: $card-spacer-y;
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: $grey;
    margin: 0;
    @include media-breakpoint-between(md, lg) {
        padding: 0 $spacer;
    }
}
