@import '../../../mixins';

%mobileActionButtonColumn {
    @include media-breakpoint-down(sm) {
        display: flex;
        > button {
            flex: 1 1 auto;
        }
    }
}

.label {
    @include media-breakpoint-down(sm) {
        margin-bottom: unset;
    }
}

.row {
    display: flex;
    align-items: center;
}

.actionsColumn {
    display: flex;
    justify-content: flex-start;
    gap: $spacer;
    flex-wrap: wrap;

    > button {
        flex: 1 1 auto;
    }
    @include media-breakpoint-down(sm) {
        flex-direction: column;
    }
}

.disabledOrganizationButton {
    background: $grey90 !important;
    color: rgba(0, 0, 0, 0.7) !important;
    opacity: 0.65;
}

.sceneContainer {
    svg {
        margin-right: $spacer / 2;
    }
    .link {
        @include link;
    }
}

.captureRowContainer {
    @include flexContainer($direction: column, $alignItems: flex-start, $gap: $spacer);

    .captureActionsContainer {
        flex-wrap: wrap;
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
    margin-bottom: $spacer;
    .flippedImage {
        transform: rotate(180deg);
    }

    .infoText {
        padding: $spacer * 1.5;
        font-size: $btn-font-size-lg;
    }
}

.networkSectionContainer {
    margin-top: $spacer * 3;

    .networkColumn {
        @include media-breakpoint-down(sm) {
            margin-bottom: $spacer;
        }
    }

    .showQRcodeColumn {
        > div {
            @extend %mobileActionButtonColumn;
        }
    }

    .disconnectColumn {
        @extend %mobileActionButtonColumn;
    }
}

.qrCodeModal {
    :global .modal-dialog {
        max-width: 95vmin;
    }
}
