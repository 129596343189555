@import '../../variables.scss';

.notRunning {
    color: $gray-600;
}

.pending {
    color: #000;
}

.done {
    color: $success;
}

.failed {
    color: $danger;
}
