@import '../../mixins.scss';

%stickySearch {
    position: sticky;
    top: 0;
    z-index: $zindex-sticky;
    height: fit-content;
}

.filterButton {
    @include secondaryButton;
    padding: $btn-padding-y $btn-padding-x;
    font-size: $font-size-smaller;
    margin-right: $spacer;
}

.filterButton.intervalPicker {
    @include flexContainer($justifyContent: center, $width: auto);
    white-space: nowrap;
    height: $button-icon-width !important;
    .intervalTextLabel {
        text-align: left;
    }
    .vsText {
        padding-left: $spacer/2;
    }
}

.filterTextLabel {
    @include text-truncate();
    display: inline-flex;
    &.textShorten {
        max-width: 10em;
    }

    &.localitiesShorten {
        max-width: 20em;
    }
}

.searchInput {
    margin: $spacer;
    width: stretch;
    @extend %stickySearch;
}

.searchInputLocalities {
    margin-bottom: $spacer;
    @extend %stickySearch;
}

@include media-breakpoint-down(sm) {
    .filterTextLabel,
    .intervalTextLabel,
    .vsText {
        max-width: 10em;
    }

    .filterButton > svg {
        margin-right: 0;
    }
}

// Locality and boundaries filter button
.localityContainer {
    @include flexContainer;
}

.localityTitleContainer {
    @include flexContainer;
    font-weight: bold;
    margin-bottom: $spacer / 2;
    white-space: nowrap;

    &:first-child {
        margin-top: $spacer / 5;
    }
}

.selectOnly {
    font-size: $font-size-small;
    color: $light-gray;
    opacity: 0.5;
    margin-left: $spacer * 1.5;

    &:hover {
        opacity: 1;
    }
}

.labelContainer {
    @include flexContainer($justifyContent: flex-start);
    gap: 0.2rem 0.3rem;
    margin-bottom: $spacer;
    max-width: $label-container-max-width;
    flex-wrap: wrap;
}
