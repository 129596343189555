@import '../../variables';

.editor {
    display: grid;
    overflow: auto;

    div {
        grid-row: 1;
        grid-column: 1;
    }
}
