@import '../../variables';

.icon,
.iconFA {
    margin-right: $spacer;
    @include media-breakpoint-down(md) {
        display: none;
    }
}

svg.icon {
    max-height: 0.9rem;
    max-width: 1rem;
    width: fit-content;
}

.counter {
    margin-left: $spacer;
    background: $grey90;
    font-weight: 500;
}
