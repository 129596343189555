@import '../../mixins.scss';

%svgInactive {
    margin-right: $spacer / 3;
    stroke: #000000;
    fill: $grey90;
}

%svgActive {
    margin-left: $spacer / 2;
    margin-right: $spacer / 3;
    stroke: white;
    fill: white;
}

.sceneObject {
    @include flexContainer($justifyContent: space-between, $direction: row);
    max-width: 95%;
    margin-left: 4%;
    background: $grey;
    color: black;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    margin-bottom: $spacer / 2;
    cursor: pointer;
    padding: $spacer / 3 3 * $spacer / 4;

    .sceneObjectNameContainer {
        > svg {
            @extend %svgInactive;
            margin-right: 3 * $spacer / 4;
        }
        .input {
            all: unset;
            width: min-content;
            max-width: 20ch;
            &:hover {
                cursor: text;
            }

            &:focus {
                border-bottom: 1px solid $grey134;
            }
        }
    }

    .sceneObjectIconsContainer {
        display: flex;
        gap: $spacer / 2;
        > svg {
            @extend %svgInactive;
            color: $grey134;
        }
        .icon {
            display: block;
            @include media-breakpoint-down(md) {
                display: none;
            }
        }
    }

    &.highlighted {
        border-color: #b3eeec;
        outline: 0;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem #b3eeec;
    }

    &.selected {
        &.entrance {
            border-color: $entranceLine;
            box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem $entranceLine;
        }

        &.passage {
            border-color: $passageLine;
            box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem $passageLine;
        }

        &.pos {
            border-color: $posZone;
            box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem $posZone;
        }

        &.queue {
            border-color: $queueZone;
            box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem $queueZone;
        }

        &.seat {
            border-color: $seatZone;
            box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem $seatZone;
        }

        &.stopZone {
            border-color: $stopZone;
            box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem $stopZone;
        }

        &.other {
            border-color: $otherZone;
            box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem $otherZone;
        }
    }
}

.describeSceneContainer {
    max-height: $describe-scene-height;
    overflow-y: auto;
    @include minimalScrollbar;
}

.describeSceneListItem {
    @include button($black, $background-color: $grey90, $hover-background-color: $vividi-primary);
    @include text-truncate();
    @include flexContainer($justifyContent: space-between, $direction: row);

    font-weight: normal;
    padding-top: $spacer / 2;
    padding-bottom: $spacer / 2;
    margin-bottom: $spacer / 2;
    max-width: 99%;

    .sceneIcons {
        display: flex;
        align-items: center;
        .inactive {
            @extend %svgInactive;
        }

        .selected {
            @extend %svgActive;
        }

        svg:last-child:not(:first-child) {
            margin-left: $spacer / 2;
        }
    }

    &:hover {
        .sceneIcons > svg {
            fill: white;
            stroke: white;
        }
    }
}

.describeSceneToggleContainer {
    display: flex;
    > button {
        flex: 1 1 auto !important;
    }
}

//Scene objects

.entrance {
    border-left: 2px solid $entranceLine;
}

.passage {
    border-left: 2px solid $passageLine;
}

.pos {
    border-left: 2px solid $posZone;
}

.queue {
    border-left: 2px solid $queueZone;
}

.seat {
    border-left: 2px solid $seatZone;
}

.stopZone {
    border-left: 2px solid $stopZone;
}

.other {
    border-left: 2px solid $otherZone;
}
