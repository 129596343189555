@import '../../variables.scss';

span.future {
    color: $red;
}

.rechartsSurface {
    svg {
        overflow: visible !important;
    }
}
