@import '../../mixins';

.modulesContainer {
    :global .custom-control-label {
        padding-top: 0 !important;
    }
}

.formControlButtonContainer {
    @include flexContainer($justifyContent: flex-end);
    gap: $spacer;
}

.inputContainer {
    @include flexContainer;
    gap: $spacer * 0.5;
}

.explanationText {
    color: $explanation-text-grey;
}
.fadeIn {
    @include fadeIn;
}

.summaryVisibilityPicker {
    max-width: $picker-max-width;
}
