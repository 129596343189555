@import '../../mixins';

.verticalListGroupContainer {
    max-height: $users-form-height;
    overflow-y: auto;
    @include minimalScrollbar;
    .stickySearch {
        width: 99%;
        margin-bottom: $spacer / 3;
        position: sticky;
        top: 0;
        z-index: 1001;
        height: fit-content;
    }
    @include media-breakpoint-between('sm', 'md') {
        max-height: $users-form-height-mobile;
    }
    &.multiSelectListContainer {
        max-height: 50vh;
    }
}

%listTab {
    @include button($black, $background-color: $grey, $hover-background-color: $vividi-primary);
    @include text-truncate();
    @include flexContainer();
    font-weight: normal;
    padding-top: $spacer / 2;
    padding-bottom: $spacer / 2;
    margin-bottom: $spacer / 3;
}

.verticalListGroupItem {
    @extend %listTab;
    // ↓ Prevent list button jumps on switch
    margin-top: 0px !important;
    // ↓ Add left "margin" to scroll bar
    max-width: 99%;
    cursor: pointer;

    .titleContainer {
        overflow: hidden;
        margin-right: $spacer / 2;
    }

    .indicators {
        @include flexContainer($width: auto, $gap: $spacer-sm);
        color: $darker;
        font-size: $font-size-smaller;
        svg {
            margin-right: 0;
            padding-bottom: 3px;
        }
    }

    &:hover,
    &.active {
        background: $vividi-primary;
        color: $white;
        .indicators {
            color: white;
        }
    }
    &.multiSelectItem {
        padding-left: $spacer;
    }

    &.disabled {
        cursor: default;
        background: transparent;
        &:hover {
            background: transparent;
            color: black;
        }
    }
}

.nonSelectableItem {
    padding-top: $spacer / 2;
    padding-bottom: $spacer / 2;
}
