@import '../mixins.scss';

.container {
    @include flexContainer(column, center, center);
    background: url('../images/dashboard_blurred.png');
    height: 100%;
    background-size: cover;
    padding-bottom: 10%;
}

.content {
    @include flexContainer(column);
    margin-top: 2rem;
    .title,
    .subtitle {
        padding: $spacer * 2;
        text-align: center;
    }
    .subtitle {
        padding-top: 0;
    }
}

.imagesContainer {
    @include flexContainer($width: 40%, $justifyContent: center);
    gap: 2rem;
    img {
        width: 80px;
        @include media-breakpoint-down(md) {
            width: 50px;
        }
    }
}

.logo {
    max-width: 500px;
    @include media-breakpoint-down(md) {
        max-width: 300px;
    }
}
