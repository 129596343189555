@import '../variables';

.infoCircle {
    font-size: $small-font-size;
    margin-left: $spacer / 2;
    text-align: center;
    color: $light-gray;
    cursor: pointer;
}

.overlayPopover {
    margin-right: $spacer;
    :global .popover-body {
        padding: 0.5 * $spacer $spacer;
        font-size: $font-size-small;
    }
}
