@import '../../mixins';

.table {
    display: grid;
    grid-template-columns: repeat(var(--ColCount), auto);
    column-gap: 0.5rem;
    row-gap: 0.8rem;

    @include media-breakpoint-down(md) {
        grid-template-columns: repeat(var(--ColCountMobile), auto);
    }

    tbody,
    thead,
    tr {
        display: contents;
    }

    td,
    th {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    // Row underline
    tr:not(tr:last-child)::after {
        content: '';
        grid-column: 1 / -1;

        border-top: $border-width solid $vividi-primary;
    }

    /* Header underline */
    thead tr::after {
        content: '';
        grid-column: 1 / -1;
        border-top: $table-border-width solid $vividi-primary;
    }

    .addonRow {
        grid-column: 1 / -1;
        padding-left: $spacer * 3;
        @include media-breakpoint-down(md) {
            padding-left: $spacer;
        }
    }

    thead tr th {
        border: none;
        color: $darker;
        font-size: 1rem;
        font-weight: 400;
        white-space: nowrap;
    }

    tbody tr {
        border-top: $border-width solid $vividi-primary;
    }

    tbody tr td {
        border: none;
        font-size: 1rem;
        white-space: nowrap;
    }

    .name {
        @include text-truncate();
        max-width: $table-name-max-width;
        color: $black;
    }

    .filteredOut {
        display: none;
    }

    .hiddenOnMobile {
        @include media-breakpoint-down(md) {
            display: none;
        }
    }

    .leftAlign {
        justify-content: start;
    }

    .centerAlign {
        justify-content: center;
    }

    .rightAlign {
        justify-content: end;
    }

    .dataValue {
        color: $black;
        font-weight: 400;
        flex: 1;
    }

    .screenText {
        @include hideOnSmallerThan;
    }
    .tableHeadWithPointer {
        cursor: pointer;
    }
}

.numericCell,
.linkCell,
.plainCell {
    justify-content: end;

    .icon {
        margin-right: $spacer;
        color: $darker;
    }
}

.sortIconContainer {
    padding-left: $spacer * 0.5;
    .sortIcon {
        font-size: $font-size-small;
        opacity: 0.3;
        padding-left: 3px;
    }
    .sortIconActive {
        @extend .sortIcon;
        opacity: 1;
        color: black;
    }
}
