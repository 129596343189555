@import '../../mixins';
@import '../../fonts';

$contentColor: #ffffff;

$safeBgColor: #01e246;
$safeDividerColor: #2af469;
$safeProgressBgColor: #a3f4bc;

$unsafeBgColor: #ff860e;
$unsafeDividerColor: #fac58f;
$unsafeProgressBgColor: #fabc7c;

$offlineBgColor: #777777;

$progressBarNotchSize: 0.2rem;

.layout {
    @include flexContainer;
    transition: all 0.1s ease;
    cursor: pointer;
    padding: $spacer;
    border-radius: $card-border-radius;
    color: $contentColor;
    font-weight: 600;
    white-space: pre-line;
    box-shadow: $container-shadow;
    filter: brightness(var(--Brightness, 1));

    &.safe {
        background: $safeBgColor;

        .progressBar {
            background: $safeProgressBgColor;
        }

        .progressBarInner {
            border-top-color: $safeBgColor;
        }
    }

    &.unsafe {
        background: $unsafeBgColor;

        .progressBar {
            background: $unsafeProgressBgColor;
        }

        .progressBarInner {
            border-top-color: $unsafeBgColor;
        }
    }

    &.offline {
        @include flexContainer(column);
        background: $offlineBgColor;

        .progressBar {
            display: none;
        }

        .progressBarArea {
            display: none;
        }

        h2 {
            text-transform: uppercase;
            font-size: 1rem;
        }
    }
}

.overlay {
    display: var(--DisplayOverlay, none);
    cursor: pointer;
    z-index: 1002;
    position: absolute;
    top: 57%;
    left: 10%;
    right: 10%;
    color: white;
    font-size: 1.2rem;
    filter: unset;

    .overlayText {
        @include flexContainer($justifyContent: center);
        align-items: center;
        svg {
            margin-right: $spacer / 2;
        }
    }
}

.statusArea {
    flex: 5 1 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: $spacer * 2;
    padding-top: $spacer * 3;
    font-size: 1rem;
    line-height: 1rem;
    text-align: center;
    text-transform: uppercase;
    height: 100%;

    .statistics {
        @include flexContainer($justifyContent: center);
        text-transform: uppercase;
        text-align: center;
        margin-top: 1rem;

        h2 {
            font-size: 0.9rem;
            margin-bottom: $spacer;
        }

        .metricContainer {
            margin: 0 0.5rem;
        }

        .userIcon {
            font-size: 0.8rem;
            margin-left: 0.8rem;
        }

        .waitTime {
            text-transform: initial;
        }
    }

    .logo {
        padding: 0.8rem 0;
        line-height: 0;

        img {
            line-height: 0;
            height: $spacer * 1.5;
        }
    }
}

.progressBarArea {
    padding-left: $spacer;
    .progressBar {
        display: flex;
        align-items: flex-end;
        padding-top: $progressBarNotchSize;
        width: 40px;
        height: 100%;
        height: 220px;

        .progressBarInner {
            width: 100%;
            background: $contentColor;
            border-top-width: $progressBarNotchSize;
            border-top-style: solid;
        }
    }
}

.estimationTextWrapper {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: baseline;
}

.estimationText {
    font-size: 1rem;
}

.dangerIcon {
    font-size: 0.8rem;
    margin-left: 0.8rem;
    margin-bottom: $spacer/2;
}

.statusText {
    font-size: 4rem;

    &.statusTextSmaller {
        font-size: 3rem;
        line-height: 3rem;
    }
}
