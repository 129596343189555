@import '../../mixins';

.imageOverlay {
    display: grid;
    grid-template-columns: 1fr;

    > * {
        grid-row-start: 1;
        grid-column-start: 1;
    }

    .overlayContainer {
        opacity: 0;
        cursor: pointer;
        transition: $normal-transition;
        &:hover {
            opacity: 1;
            background: rgba(0, 0, 0, 0.4);
        }
        .overlayText {
            @include flexContainer($justifyContent: center, $alignItems: center);
            font-size: $font-size-device-input;
            height: 100%;
            color: white;

            svg {
                transform: translateY(5px);
                font-size: 2rem;
                margin-right: $spacer;
            }
        }
    }
}
