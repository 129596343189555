@import '../../../mixins';

.navigationConfirmModal {
    .header {
        @include flexContainer($justifyContent: space-between);
        padding: $spacer $spacer * 1.5;
        > svg {
            transform: scale(1.5);
        }
    }

    .buttonRow {
        margin-top: $spacer * 2;
        @include flexContainer($justifyContent: flex-end, $gap: $spacer);
    }
}
