@import '../../mixins.scss';

.modal {
    :global .modal-body {
        padding: unset;
    }

    & > div {
        @include media-breakpoint-up(md) {
            max-width: map-get($container-max-widths, 'xl');
        }
        @include media-breakpoint-down(sm) {
            max-width: 100%;
        }
    }
}

.modalContainer {
    @include media-breakpoint-down(sm) {
        width: 100%;
    }

    > button {
        flex: 1 1 auto;
    }
}

.placeholderBox {
    min-height: $placeholder-box;
}
