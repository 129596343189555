@import '../../mixins';

.scenesEditForm {
    :global .form-group {
        align-items: center;

        .label {
            @include media-breakpoint-down(sm) {
                margin-bottom: unset;
            }
        }
    }
}
.toggleTextContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.deviceContainer {
    @include flexContainer($gap: $spacer);
    .link {
        @include link;
    }

    .deviceNote {
        color: $text-muted;
        margin-left: $spacer;
    }
}

.detectSettingsColumn {
    @include flexContainer($justifyContent: flex-start, $gap: $spacer);
    flex-wrap: wrap;
}

.trackerConfigDialog {
    :global .modal-content {
        border: unset;
        border-radius: $border-radius;

        @include media-breakpoint-up(md) {
            min-width: 600px;
        }
    }

    :global .modal-header {
        background: $grey;
        font-weight: bold;
        border-bottom: unset;
    }

    .optionSelectRow {
        @include flexContainer($justifyContent: space-between, $gap: $spacer, $direction: row);
        flex-wrap: wrap;
    }

    .configurationInput {
        margin-top: $spacer;
    }

    .warning {
        color: $danger;
        margin-top: $spacer;
    }

    .actionsRow {
        margin-top: $spacer;
        @include flexContainer($justifyContent: flex-end, $gap: $spacer, $direction: row);
    }
}

.actionsColumn {
    @include flexContainer($justifyContent: flex-start, $gap: $spacer, $alignItems: normal);
    flex-wrap: wrap;

    @include media-breakpoint-down(sm) {
        flex-direction: column;
    }
}

.imageContainer {
    display: flex;
    row-gap: $spacer;
    .imageHeaderContainer {
        @include flexContainer();
        margin-bottom: $spacer;
    }

    .saveButton {
        margin-top: $spacer;
        width: 100% !important;
        &.hiddenSaveButton {
            display: none;
        }
    }

    .alertContainer {
        margin-top: $spacer;
    }

    .errorContainer {
        @include flexContainer($justifyContent: center);
        aspect-ratio: 4/3;
        font-size: $font-size-data-display-box;
        color: $darker;
        flex-direction: column;
        background: $grey90;

        svg {
            margin-right: $spacer;
            font-size: 2rem;
        }
        .infoText {
            padding: $spacer * 1.5;
            font-size: $btn-font-size;
        }

        @include media-breakpoint-between(sm, md) {
            font-size: $font-size-device-input;
            svg {
                margin-right: $spacer;
                font-size: 1.5rem;
            }
        }
    }
}
