@import '../../variables';
@import '../../fonts';

$contentColor: #ffffff;

$safeBgColor: #01e246;
$safeDividerColor: #2af469;
$safeProgressBgColor: #a3f4bc;

$unsafeBgColor: #ff860e;
$unsafeDividerColor: #fac58f;
$unsafeProgressBgColor: #fabc7c;

$offlineBgColor: #777777;

$progressBarNotchSize: 0.5vh;

.layout {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    color: $contentColor;
    font-weight: 600;
    white-space: pre-line;

    &.safe {
        background: $safeBgColor;

        .progressBar {
            background: $safeProgressBgColor;
        }

        .progressBarInner {
            border-top-color: $safeBgColor;
        }
    }

    &.unsafe {
        background: $unsafeBgColor;

        .progressBar {
            background: $unsafeProgressBgColor;
        }

        .progressBarInner {
            border-top-color: $unsafeBgColor;
        }
    }

    &.offline {
        background: $offlineBgColor;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;

        .progressBar {
            display: none;
        }

        .progressBarArea {
            display: none;
        }

        h2 {
            text-transform: uppercase;
            font-size: 10vh;
        }
    }
}

.statusArea {
    flex: 5 1 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    font-size: 16vh;
    line-height: 18vh;
    text-align: center;
    text-transform: uppercase;
    height: 100%;

    // A counterweight for the logo so that the status text stays in the middle
    &::before {
        content: '';
        height: 16vh;
    }

    .statistics {
        display: flex;
        flex-direction: row;
        justify-content: center;
        text-transform: uppercase;
        text-align: center;
        font-size: 6.5vw;
        width: 100%;

        h2 {
            font-size: 3vw;
            margin-bottom: 3vh;
        }

        .metricContainer {
            margin: 0 2.5vw 0 2.5vw;
        }

        .userIcon {
            font-size: 6.5vw;
            margin-left: 2rem;
            margin-bottom: $spacer/2;
        }

        .waitTime {
            text-transform: initial;
        }
    }

    .logo {
        padding: 5vh 0;
        line-height: 0;

        img {
            line-height: 0;
            height: 6vh;
            width: 24.3vh;
        }
    }
}

.progressBarArea {
    flex: 1 1 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 70vh;
    width: 100%;

    .progressBar {
        display: flex;
        align-items: flex-end;
        padding-top: $progressBarNotchSize;
        width: 100%;
        height: 100%;
        margin-left: 8vw;

        .progressBarInner {
            width: 100%;
            background: $contentColor;
            border-top-width: $progressBarNotchSize;
            border-top-style: solid;
        }
    }
}

.estimationTextWrapper {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: baseline;
}

.estimationText {
    font-size: 6.5vw;
}

.dangerIcon {
    padding: $spacer * 2;
}

.statusText {
    font-size: 15vw;
    margin-bottom: 8vh;

    &.statusTextSmaller {
        font-size: 11vw;
        line-height: 11vw;
    }
}
