@import '../../mixins';

.layoutVertical {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: min-content;
}

.layoutHorizontal {
    display: grid;
    grid-auto-flow: column;
    grid-template-rows: 1fr;
}

.extraSmallGap {
    gap: $spacer-xs;
}

.smallGap {
    gap: $spacer;
}

.mediumGap {
    gap: $spacer-md;
}

.largeGap {
    gap: $spacer-lg;
}
