@import '../../../mixins';

.buttonContainer {
    justify-content: end;
    gap: $spacer;
}

.deviceIconContainer {
    margin-right: $spacer;

    &.black.mobile > {
        svg {
            color: $black;
        }
    }

    &.white.mobile > {
        svg {
            color: white !important;
            stroke: black;
            stroke-width: 30px;
        }
    }

    &.black > div:first-child svg {
        color: $black;
    }

    &.white > div:first-child svg {
        color: white !important;
        stroke: black;
        stroke-width: 30px;
    }
}

.placeholderBox {
    min-height: $placeholder-box;
}

.controlButtonRow {
    @include flexContainer($justifyContent: flex-end);
    gap: $spacer;
}

.noDevicesInOrganizationContainer {
    .noDevices {
        color: $light-gray;
        font-size: $font-size-larger;
    }
}

.clickable {
    &:hover {
        cursor: pointer;
    }
}
