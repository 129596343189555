@import '../../mixins';

.highlightsContainer {
    @include flexContainer($justifyContent: center);
}

.queueFlowImage {
    @include hideOnSmallerThan(sm);
    display: flex;
    margin: $spacer auto 0;
    width: 60%;
}

.queueSizePlot,
.queueTimesPlot {
    min-height: $queue-plot-min-height;
}

.plot {
    min-height: $queue-plot-min-height;

    :global .card-title {
        margin-bottom: $spacer;
    }

    :global .card-body {
        display: grid;
        grid-template-rows: min-content min-content minmax(0, 1fr);
    }
}

.plotControlsLabel {
    margin-right: $spacer;
}

.heatmapCard {
    height: $heatmap-carousel-height !important;
}
