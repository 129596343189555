@import '../variables';

.localityStatusContainer {
    text-align: center;
    color: '#73C831' !important;
}

.localityStatusIcon {
    font-size: '0.7em' !important;
}

.preferencesEditButton {
    margin-right: $spacer * 2;
}
