@import '../../variables';

.placeholderBox {
    min-height: $placeholder-box;
}

.localityPicker {
    margin-bottom: $spacer;
    > * {
        width: 98% !important;
    }
}
