@import '../../variables';
@import '~bootstrap/scss/tables';

.termsAndConditions {
    table {
        @extend .table;
    }

    h2 {
        text-transform: uppercase;
    }

    ul li {
        color: $body-base;
    }

    :global .card-header {
        padding: 0;

        button {
            width: 100%;
            text-align: left;
            padding: $card-spacer-y $card-spacer-x;
        }
    }
}
