@import '../../mixins';

.heading {
    text-align: center;
}

.disabledDeleteSceneButton {
    background: $grey90 !important;
    color: rgba(0, 0, 0, 0.7) !important;
    opacity: 0.65;
}
