@import '../mixins';

.questionMarkIcon {
    font-size: $font-size-small;
    margin-left: 0.25rem;
}

.labelWithIcon {
    display: flex;
    cursor: pointer;
}

.formContainer {
    margin-top: $spacer * 1.5;
}

.buttonGroupRight {
    display: flex;
    justify-content: flex-end;
    gap: $spacer;
}
