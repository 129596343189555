@import '../../mixins';

.toggleIconContainer {
    padding-right: $spacer;
    .toggleIcon {
        color: $light-gray;
        cursor: pointer;

        &.selected {
            color: $vividi-primary-dark;
        }
    }
}
