@import '../../variables';

.responsiveTable {
    :global .btn {
        &:not(:first-child) {
            margin-left: $spacer;
        }
    }

    th {
        white-space: nowrap;
    }

    @include media-breakpoint-up(lg) {
        width: auto;

        tr th:first-child {
            width: 100%;
        }

        td:not(:first-child),
        th:not(:first-child) {
            text-align: right;
        }

        td {
            white-space: nowrap;
        }
    }
}

.verticalTable {
    th {
        white-space: nowrap;
    }

    @include media-breakpoint-down(md) {
        display: block;

        thead,
        tbody,
        tr,
        td,
        th {
            display: block;
        }

        thead th {
            border: 0;
            padding-top: 0.5em;
            padding-bottom: 0.5em;
        }

        td,
        th {
            white-space: normal;
            border: 0;
        }

        tbody tr {
            border-top: $table-border-width solid $table-border-color;
            margin-top: $spacer;
        }
    }
}

.horizontalTable {
    @include media-breakpoint-down(md) {
        th {
            width: 30%;
        }
        tr td:last-child {
            :global .btn {
                margin-left: 0 !important;
                margin-bottom: $spacer / 2;
                width: 100%;
            }
        }
        tr td:first-child {
            word-break: break-word;
        }
    }
}
