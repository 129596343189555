@import '../mixins';

.dropDown {
    display: flex;
    flex-direction: column !important;
    gap: $spacer;
}

.linkCell {
    @include link;
}

.userLink {
    @include link;

    .text {
        margin-left: $spacer;
    }
}

.monitoringCell {
    justify-content: center;
}

.actionButtonContainer {
    padding-left: $spacer;

    &:hover {
        cursor: pointer;
    }
}
