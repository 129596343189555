@import '../mixins.scss';

.badgeEllipsis {
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 10em;
    margin-left: $spacer/2;
}

.badge {
    height: 1.2rem;
}

.badgeList {
    display: flex;
    flex-wrap: wrap;
    gap: $spacer * 0.4;
    max-width: 300px;
}

.inlineBadgeList {
    display: inline-block;
}

.moreText {
    cursor: pointer;
}
