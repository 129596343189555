@import '../../mixins.scss';
@import '../../fonts';

.conversionPlotContainer {
    margin-bottom: $spacer;

    :global .recharts-xAxis .recharts-cartesian-axis-tick-line {
        display: none;
    }
    @include plotContainer;
}

.conversionPlot {
    @include plot;
}

.heatmapCard {
    height: $heatmap-carousel-height !important;
}

.conversionChangeIndicatorLayout {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: $spacer * 5;
    margin-left: $spacer * 3;
    margin-right: $spacer * 3;

    @include media-breakpoint-up(sm) {
        grid-template-columns: repeat(auto-fit, 1fr);
    }
    margin-bottom: $spacer;
}

.conversionChangeIndicator {
    height: $spacer * 1.5;
    background-color: $grey90;
    border-radius: $spacer * 2;
}

.conversionChangeIndicatorText {
    @include flexContainer;

    .changeText {
        color: $darker;
    }

    .changePercentage,
    .bouncedPercentage {
        color: $vividi-primary;
        font-weight: $weight-bold;
        font-size: $font-size-conversion-rates;
    }

    .bouncedPercentage {
        color: $palette-bounced-red;
    }
}
