@import '../../mixins';

.floorplanContainer {
    text-align: center;
}

.floorplanDropdownContainer {
    @include flexContainer($justifyContent: flex-start);
}

.floorplanButtonsContainer {
    display: flex;
    gap: $spacer;
    @include media-breakpoint-down(sm) {
        svg {
            display: none;
        }
    }
}

.floorplanRow {
    @include flexContainer($justifyContent: center, $alignItems: center);
}

.floorplanIcon {
    width: $spacer;
}

.floorplanImageContainer {
    margin-top: $spacer * 3;
    padding-bottom: $spacer * 2;
}

.floorplanErrorMessage {
    font-size: $font-size-lg;
    color: $negativeTrend;
}

.scaleEditForm {
    margin-top: $spacer * 2;

    input {
        width: 5em !important;
    }
}

.floorplanButton {
    margin-left: $spacer;
}

.nameEditForm {
    @include flexContainer($justifyContent: center);
}

.intensityLegendContainer {
    @include flexContainer;
}

.legendTitleContainer {
    @include flexContainer;
}
