@import '../../../mixins';

.assignDeviceContainer {
    display: grid;
    grid-template-columns: 2fr 1fr 2fr;
    padding: $spacer * 2;

    @include media-breakpoint-down(md) {
        grid-template-columns: 1fr;
    }

    .availableDevicesContainer {
        @include flexContainer($direction: column, $alignItems: unset, $gap: $spacer, $justifyContent: unset);
    }

    .controlSwitchContainer {
        @include flexContainer($direction: column, $alignItems: center, $gap: $spacer, $justifyContent: center);
    }

    .deviceIconContainer {
        &.black > div:first-child svg {
            color: $black;
        }

        &.white > div:first-child svg {
            color: white !important;
            stroke: black;
            stroke-width: 30px;
        }
    }
}

.devicesToOrganizationButtonContainer {
    @include flexContainer($direction: column, $alignItems: center, $gap: $spacer, $justifyContent: center);

    > button {
        max-width: fit-content;
        padding: $spacer;
        font-size: $font-size-device-input;
    }
}
