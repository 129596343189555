@import '../../../variables.scss';

.plotContainer {
    min-height: 12.5rem;
    position: relative;

    @include media-breakpoint-up(md) {
        min-height: 14rem;
    }

    @include media-breakpoint-up(lg) {
        min-height: 15rem;
    }
}

.plot {
    width: 100%;
    height: 100%;
}
