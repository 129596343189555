@import '../../mixins';

.sideNavigationLayout {
    display: grid;
    grid-template-columns: 1fr 2fr;

    @include media-breakpoint-down(md) {
        grid-template-columns: 1fr;
    }
}

.smallColumnGap {
    column-gap: $spacer-sm;
}

.mediumColumnGap {
    column-gap: $spacer-md;
}

.largeColumnGap {
    column-gap: $spacer-lg;
}

.smallRowGap {
    row-gap: $spacer-sm;
}

.mediumRowGap {
    row-gap: $spacer-md;
}

.largeRowGap {
    row-gap: $spacer-lg;
}
