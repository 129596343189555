@import '../../mixins';

.timelineDatePicker {
    @include flexContainer($justifyContent: flex-end, $width: auto);
    gap: $spacer;
    font-size: $font-size-smaller;

    .navigationButtonHidden {
        opacity: 0;
    }
    .rangeDisplayButton {
        min-width: 200px;
        pointer-events: none;
    }
}
