@import '../mixins.scss';

.modal {
    :global .modal-body {
        padding: unset;
    }

    & > div {
        @include media-breakpoint-up(md) {
            max-width: map-get($container-max-widths, 'xl');
        }
        @include media-breakpoint-down(sm) {
            max-width: 100%;
        }
    }
}

.floorplanNames {
    > svg {
        margin-right: $spacer * 2;
        width: $spacer;
        color: $darker;
    }
}

.link {
    cursor: pointer;
    @include link;
}
