@import '../variables';
@import '~bootstrap/scss/dropdown';
@import '~bootstrap/scss/forms';

.suggests {
    @extend .dropdown-menu;
    display: block;
}

.suggestsHidden {
    @extend .dropdown-menu;
    display: none;
}

.suggestsItem {
    @extend .dropdown-item;
}

.suggestsItemActive {
    @extend .active;
}

.input {
    @extend .form-control;
}
