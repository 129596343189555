@import '../mixins';

.toolbar {
    @supports (position: sticky) {
        position: sticky;
        top: 0;
        z-index: $zindex-sticky;
    }

    max-width: var(--ContainerWidth, $details-page-max-width);
    margin-left: auto;
    margin-right: auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    gap: $spacer;

    > *:last-child {
        text-align: right;
    }

    padding: 1.5 * $spacer;
    background: $grey;
    @include media-breakpoint-down(md) {
        display: flex;
        padding-right: $spacer;
        padding-left: $spacer;
        justify-content: space-between;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .localityPickerContainer {
        @include flexContainer($justifyContent: flex-start);
        :global .dropdown {
            margin-right: $spacer;
        }
    }
}

.content {
    max-width: $details-page-max-width;
    min-height: 100vh;
    background: $grey !important;

    :global .card,
    :global .card-body {
        height: 100%;
    }

    :global .row {
        margin-bottom: $card-spacer-y;

        &:last-child {
            margin-bottom: 0;
        }

        > * {
            padding-left: 0.75 * $spacer;
            padding-right: 0.75 * $spacer;
        }

        @include media-breakpoint-down(sm) {
            .col-md-6:first-child {
                margin-bottom: $card-spacer-y;
            }
        }
    }
}

.granularityPicker {
    max-width: $picker-max-width;
}

.intervalPicker {
    @extend .granularityPicker;
}
