@import '../../mixins.scss';

.buttonToolbarContainer {
    @include flexContainer($direction: row, $justifyContent: space-between);
    @include media-breakpoint-down(md) {
        display: none;
    }

    button {
        padding: $spacer / 2 $spacer;
        > svg {
            fill: none;
            stroke: #fff;
        }
    }

    .boundaries {
        @include flexContainer($direction: row, $justifyContent: flex-start);
        gap: $spacer;

        .entranceButton {
            @include button(
                $background-color: $entranceLine,
                $hover-background-color: $entranceLineHover,
                $shadow: $button-shadow-light
            );
        }

        .passageButton {
            @include button(
                $background-color: $passageLine,
                $hover-background-color: $passageLineHover,
                $shadow: $button-shadow-light
            );
        }
    }

    .zones {
        @include flexContainer($direction: row, $justifyContent: flex-end);
        gap: $spacer;

        .posButton {
            @include button(
                $background-color: $posZone,
                $hover-background-color: $posZoneHover,
                $shadow: $button-shadow-light
            );
        }

        .queueButton {
            @include button(
                $background-color: $queueZone,
                $hover-background-color: $queueZoneHover,
                $shadow: $button-shadow-light
            );
        }

        .seatButton {
            @include button(
                $background-color: $seatZone,
                $hover-background-color: $seatZoneHover,
                $shadow: $button-shadow-light
            );
        }

        .stopZoneButton {
            @include button(
                $background-color: $stopZone,
                $hover-background-color: $stopZoneHover,
                $shadow: $button-shadow-light
            );
        }
    }
}

.saveButtonsContainerVisible {
    visibility: visible;
}

.saveButtonsContainerHidden {
    visibility: hidden;
}

.accordionToggleStyles {
    cursor: pointer;
    @include flexContainer($direction: row, $alignItems: center, $justifyContent: flex-start);
    margin-top: $spacer;
    background: $grey;
    padding: $spacer;

    svg {
        margin-right: $spacer;
    }
}
