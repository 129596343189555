@import '../mixins';

.notificationsHeaderContainer {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2 * $spacer;
    .notificationHeading {
        font-weight: $font-weight-header;
    }
    .notificationButton {
        @include button;
    }
}

.headerRowContainer {
    margin-bottom: $spacer * 0.5;
}

.notificationsActionButtonContainer {
    display: flex;
    justify-content: space-between;
    .cancelButton {
        margin-right: $spacer;
    }
}

.noActiveNotificationContainer {
    margin-bottom: $spacer;
    .noActiveNotificationsSpan {
        color: $light-gray;
        font-size: $font-size-larger;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    16% {
        opacity: 1;
    }
    84% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    16% {
        opacity: 1;
    }
    84% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}

.fadeOut {
    animation: fadeOut 3s;
}
.fadeIn {
    animation: fadeIn 3s;
}
