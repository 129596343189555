@import '../../mixins';

.occupancyTimelineContainer {
    flex-grow: 1;
    display: grid;
    grid-row-gap: $spacer;
    grid-column-gap: $spacer / 2;
    grid-template-columns: #{minmax(auto, 180px) repeat(var(--ColumnCount), 1fr)};
    grid-auto-rows: 25px;

    .inactiveTimelineSubSection {
        height: 100%;
        display: inline-block;
        background-color: $light-gray;
        width: var(--SubsectionWidth, 0%);
        background-color: var(--SubsectionColor, $grey90);
        opacity: var(--SubsectionOpacity, 100%);
    }

    .timelineSubSection {
        cursor: pointer;
        height: 100%;
        display: inline-block;
        width: var(--SubsectionWidth, 0%);
        background-color: var(--SubsectionColor, $grey90);
    }

    .zoneName {
        @include text-truncate;
    }
}

.legendContainer {
    @include flexContainer($justifyContent: center);
    grid-column: 2 / var(--ColumnSpan);
    gap: $spacer * 2;
    .occupiedSquare {
        margin-right: $spacer;
        color: $vividi-primary;
    }
    .freeSquare {
        margin-right: $spacer;
        color: $grey90;
    }
    .closedSquare {
        margin-right: $spacer;
        color: $light-gray;
        opacity: 50%;
    }
}

.openHoursContainer {
    @include flexContainer;
    padding: $spacer 0;
    font-size: $font-size-smaller;
    color: $darker;
    margin-top: -$spacer;
}

.weekdayContainer {
    @include flexContainer($justifyContent: center);
    margin-bottom: -$spacer;
}
