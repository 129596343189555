@import '../../variables.scss';

.row {
    justify-content: center;
}

.wrapper {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    margin-top: $spacer * 2;
    margin-bottom: $spacer;
    border-right: none;

    &:last-of-type {
        border-right: none;
    }

    @include media-breakpoint-up(xl) {
        border-right: $border-width solid $light-border;
        padding: 0 $spacer * 1.5;

        &:first-of-type {
            padding-left: $spacer;
        }

        &:last-of-type {
            padding-right: 0;
        }
    }

    &.maxWidthFitContent {
        @include media-breakpoint-up(xl) {
            max-width: fit-content;
        }
    }

    .label {
        display: flex;
        align-items: center;
        flex-flow: row;
        flex-wrap: nowrap;
        font-size: 0.9rem;
        color: $darker;
        text-align: center;
        white-space: nowrap;

        @include media-breakpoint-up(lg) {
            font-size: 1rem;
        }
    }
}
