@import '../../mixins';

.accordion {
    display: contents;
}

.toggle {
    @include button($black, $background-color: $grey, $hover-background-color: $vividi-primary);

    &.active {
        @include button($white, $background-color: $vividi-primary);
    }

    @include text-truncate();
    @include flexContainer($justifyContent: flex-start);

    padding-top: $spacer / 2;
    padding-bottom: $spacer / 2;

    min-width: 100%;
    text-align: left;
    font-weight: normal;

    .caret {
        margin-left: auto;
    }

    // Caret alignment
    svg:last-child {
        margin-right: 0 !important;
    }
}
