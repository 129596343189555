@import '../../mixins.scss';

.sceneEntry {
    @include button();
    margin-top: $spacer !important;
    margin-bottom: unset;
    max-width: 100%;
    padding: $spacer;
}

%thickButton {
    width: 100% !important;
    padding: $spacer !important;
}

.installerUIContainer {
    > button {
        margin-top: $spacer;
        width: 100% !important;
    }
}

.modalContainer {
    :global .modal-body {
        min-height: 98vh;
        display: flex;
        flex-direction: column;

        > button {
            width: 100% !important;
            margin-bottom: $spacer;
        }
    }
    .header {
        text-align: center;
    }
}

.assignToSceneViewContainer {
    display: flex;
    flex-direction: column;
    gap: $spacer * 1.5;

    .numberInput {
        padding: $spacer * 2;
        font-size: 3rem;
    }

    > h1 {
        font-weight: 600;
        color: $vividi-primary-dark;
    }

    > h2 {
        color: $dark-grey;
        font-size: 2.5rem;
    }

    .alert {
        margin-bottom: 0 !important;
    }

    > button {
        @extend %thickButton;
        &.previousStepButton {
            padding-top: 0 !important;
        }
    }

    .saveSnapshotButtonContainer {
        @include flexContainer($direction: column, $gap: $spacer, $justifyContent: unset);
        margin-top: auto;

        > button {
            @extend %thickButton;
        }
    }

    .liveViewCounterRow {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        align-items: center;

        .liveViewText {
            font-size: 1.2rem;
        }

        .liveViewCounter {
            @extend .liveViewText;
            font-weight: 500;
            color: $vividi-primary-dark;
        }
        .loadingLiveViewIndicator {
            width: $spacer;
            height: $spacer;
            border-width: 0.15rem;
            margin-left: $spacer / 2;
        }
    }

    .imageWrapperContainer {
        aspect-ratio: 3 / 4;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
            transform: rotate(90deg);
            height: 85vw;
            &.flippedImage {
                transform: rotate(270deg) !important;
            }
        }
    }
}
