@import '../../mixins';

.defaultContainer {
    padding-right: 0;
    padding-left: 0;

    margin-bottom: $spacer-md;
    min-height: 100vh;
    background: $grey !important;
}

.topPadding {
    padding-top: $card-spacer-y;
}

.pageHeading {
    padding-top: $spacer;
    padding-bottom: $spacer;
}

.sectionHeading {
    @include flexContainer();
    white-space: nowrap;

    h1 {
        margin-bottom: 0;
        line-height: 1;
    }

    .btn-toolbar {
        margin-left: auto !important;

        .btn:not(:first-child) {
            margin-left: $spacer;
        }
    }
}

.breadcrumbs {
    margin-top: $spacer;
}

.footerContainer {
    position: absolute;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 3rem;
    width: 100%;
    border-top: 1px solid $grey90;
    color: $light-gray;
    font-size: $font-size-smaller;
    padding: 0 $spacer;

    span {
        padding-right: $spacer * 2;
    }
}
