@import '../../mixins';

.iconButton {
    @include button(
        black,
        $grey90,
        $vividi-primary-dark,
        $vividi-primary-light,
        $font-size: $spacer,
        $indent-icon: false
    );
    text-align: center;
    height: $button-icon-width;
    width: $button-icon-width;
}

.iconButton:disabled {
    background: $grey90;
    color: #00000066;
    cursor: unset;
}
