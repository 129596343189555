@import '../../../mixins.scss';

.editable {
    .node {
        &:hover {
            cursor: move;
            fill: $white !important;
            stroke: $white !important;
        }
    }
}

.zone {
    .zoneArea {
        fill-opacity: 0.3;

        &:hover {
            cursor: pointer;
        }
    }

    .zoneEdge {
        stroke-width: 2;
        cursor: pointer;
    }

    &.posZone {
        .zoneArea,
        .zoneEdge,
        .node {
            fill: $posZone;
            stroke: $posZone;
        }
    }

    &.queueZone {
        .zoneArea,
        .zoneEdge,
        .node {
            fill: $queueZone;
            stroke: $queueZone;
        }
    }

    &.seatZone {
        .zoneArea,
        .zoneEdge,
        .node {
            fill: $seatZone;
            stroke: $seatZone;
        }
    }

    &.stopZone {
        .zoneArea,
        .zoneEdge,
        .node {
            fill: $stopZone;
            stroke: $stopZone;
        }
    }

    &.otherZone {
        .zoneArea,
        .zoneEdge,
        .node {
            fill: $otherZone;
            stroke: $otherZone;
        }
    }

    &.highlighted {
        .zoneArea {
            fill: $white;
        }
    }

    &.editable {
        .zoneArea {
            cursor: move;
        }

        .zoneEdge {
            stroke-width: 3;
            cursor: col-resize;

            &:hover {
                stroke: $white;
            }
        }
    }
}

.boundary {
    cursor: pointer;

    .background,
    .border {
        stroke-linecap: round;
        stroke-linejoin: round;
    }

    .background {
        stroke-width: 2;
        stroke: $white;
    }

    .border {
        stroke-width: 6;
        stroke: $grey;
    }

    &.editable {
        .segment {
            cursor: col-resize;

            stroke-width: 6;
            stroke: transparent;

            &:hover {
                stroke: $white;
            }
        }
    }

    &.highlighted,
    &.editable {
        &.passageBoundary {
            .background {
                stroke: $passageLine;
            }
        }

        &.entranceBoundary {
            .background {
                stroke: $entranceLine;
            }
            .arrow {
                fill: $entranceLine;
                fill-opacity: 1;
            }
        }

        &.otherBoundary {
            .background {
                stroke: $otherLine;
            }
            .arrow {
                fill: $otherLine;
                fill-opacity: 1;
            }
        }
    }

    &.entranceBoundary {
        .node {
            fill: $entranceLine;
            stroke: $entranceLine;
        }

        .arrow {
            fill: $white;
            fill-opacity: 0.7;
            stroke: $entranceLine;
            stroke-width: 1;
        }

        .border {
            stroke: $entranceLine;
        }
    }

    &.passageBoundary {
        .arrow {
            display: none;
        }
        .node {
            stroke: $passageLine;
            fill: $passageLine;
        }
        .border {
            stroke: $passageLine;
        }
    }

    &.otherBoundary {
        .arrow {
            fill: $white;
            fill-opacity: 0.7;
            stroke: $otherLine;
            stroke-width: 1;
        }
        .node {
            stroke: $otherLine;
            fill: $otherLine;
        }
        .border {
            stroke: $otherLine;
        }
    }
}
