@import '../mixins.scss';

.container {
    display: flex;
    height: 100vh;
    margin-top: -$navbar-height;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.content {
    flex: 0 1 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 3rem;
}

.logo {
    max-height: 5rem;
}

.notFound {
    font-family: $font-family-sans-serif;
    font-size: 3rem;
    color: $info;
}

.message {
    font-family: $font-family-sans-serif;
    font-size: 1.5rem;
    padding-top: 2rem;
}

.link {
    @include link;
}
