@import '../mixins';

.heatmapThumbnailContainer {
    @include minimalScrollbar;
    position: absolute;
    display: block;
    top: 80px;
    left: 0;
    min-width: $heatmap-thumbnail-width;
    max-height: var(--CarouselWidth, $heatmap-carousel-max-width);
    margin: 0 0 0 -2.5 * $spacer;
    overflow-y: auto;
    transform: rotate(-90deg) translateY(-250px);
    transform-origin: right top;
    direction: rtl;

    @include media-breakpoint-down(lg) {
        max-height: 80vw;
    }

    // Fix tablet, mobile horizontal scrolling
    overflow-x: auto;
    &::-webkit-scrollbar:horizontal {
        display: none;
    }
}

.heatmapThumbnail {
    cursor: pointer;
    display: block;
    transform-origin: right top;
    padding: $spacer 0;
    max-width: 80vw;
    transform: rotate(90deg) translateX($heatmap-thumbnail-width);
}

.heatmapCustomThumbnail {
    @extend .heatmapThumbnail;
    padding: $spacer * 1.5 0;
}

.heatmapThumbnailModal {
    @extend .heatmapThumbnail;
    transform: none;
}

.heatmapImage {
    border-radius: $border-radius;
}

.heatmapOverlayContainer {
    display: grid;
    padding-top: $spacer * 0.5;
    padding-bottom: $spacer * 0.6;
    overflow: hidden;

    * {
        grid-row: 1;
        grid-column: 1;
    }
}

.boundaryLine {
    stroke: $line-outgoing;
    stroke-linecap: round;
    stroke-width: 1.5%;
}

.heatmapImageModal {
    max-width: 80vw;

    :global .modal-body {
        display: flex;
        justify-content: center;
    }
}

.overlayedBoundaryHeader {
    @include flexContainer;
    .highlightedValue {
        direction: ltr;
        color: $vividi-primary;
    }
    font-weight: $font-weight-button;
}

.arrow {
    fill: $line-outgoing;
}
