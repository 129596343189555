@import '../../mixins';

.pieChartContainer {
    @include flexContainer($direction: row, $alignItems: flex-end, $justifyContent: center);
    gap: $spacer * 2;
}

.pieChartLegendWideColumn {
    @include flexContainer($alignItems: center, $direction: column, $justifyContent: center);
    gap: $spacer * 2;

    .labelText {
        margin-left: $spacer;
        @include text-truncate();
    }

    .labelValue {
        font-size: $font-size-jumbotron;
        font-weight: 500;
        text-align: center;
        color: $darker;
        min-width: 102px;
    }
}

.centeredPieChart {
    left: 30%;
    transform: translateX(-30%);
}

.plot {
    @include plot;
}

.visitorsInTimePlot {
    min-height: $visitors-in-time-min-height;
}

.capacityEditButton {
    position: relative;
    bottom: 75px;
    left: 100px;
    opacity: 0.7;
    width: fit-content;

    button {
        margin-left: $spacer;
    }

    span {
        min-width: $spacer * 3;
        text-align: left;
    }
}

.staySafeScreenButtonContainer {
    @include flexContainer($width: auto, $justifyContent: flex-end);
}
.staySafeScreenLanguageDropdown {
    :global .btn-secondary {
        margin-right: $spacer * 0.5;
        padding-left: $spacer;
        padding-right: $spacer * 0.5;
        svg {
            margin: unset;
        }
    }

    :global .btn-secondary.dropdown-toggle,
    :global .btn-secondary.dropdown,
    :global .btn-secondary.dropdown-toggle:focus,
    :global .btn-secondary:not(:disabled):not(.disabled):active,
    :global .show > .btn-secondary.dropdown-toggle {
        svg {
            margin: unset;
        }
    }
}

.crowdDisplayNumber {
    font-size: $font-size-statistic-box-large;
    font-weight: 500;
}

.heatmapCard {
    min-height: $heatmap-carousel-height !important;
}
