@import '../../../mixins.scss';

.maximumOccupancy {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    font-size: 1.4rem;

    button {
        margin-left: 1rem;
    }

    span {
        flex: 1;
        width: $spacer * 5;
        text-align: right;
    }
}

.headingCenterText {
    text-align: center;
}

.cell {
    display: flex;
    width: 100%;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
}

.staySafeButton {
    @include button();
    white-space: nowrap;
}
