@import '../../../mixins';

.localitiesEditForm {
    .row {
        align-items: center;
    }

    .label {
        @include media-breakpoint-down(sm) {
            margin-bottom: unset;
        }
    }

    .actionsRow {
        align-items: baseline;
        @include media-breakpoint-down(sm) {
            margin-bottom: unset;
        }
    }

    .actionsColumn {
        @include flexContainer($justifyContent: flex-end, $gap: $spacer, $direction: row);
        flex-wrap: wrap;

        @include media-breakpoint-down(sm) {
            div > button,
            button {
                flex: 1 1 auto;
                width: 100% !important;
            }
        }
    }

    .saveChangesButtonColumn {
        flex-direction: column;
        display: flex;
        padding: unset;
        margin-bottom: $spacer;

        button > {
            flex: 1 1 auto;
        }
    }

    .badgePickerContainer {
        .allLocalitiesButtonColumn {
            display: flex;
            flex-direction: column;

            button > {
                flex: 1 1 auto;
            }

            @include media-breakpoint-down(sm) {
                margin-top: $spacer;
            }
        }

        .label {
            @include media-breakpoint-down(sm) {
                margin-bottom: unset;
            }
        }

        .picker {
            padding-right: 0;
        }
    }

    .questionMarkIcon {
        font-size: $font-size-small;
        margin-left: 0.25rem;
    }

    .floorplanNames {
        > svg {
            margin-right: $spacer * 2;
            width: $spacer;
            color: $darker;
        }
    }

    .detailButton {
        flex: 1 1 auto;
    }

    .uploadButton {
        @include media-breakpoint-down(sm) {
            margin-top: $spacer;
            flex: 1 1 auto;
            margin-left: $bootstrap-column-padding;
            margin-right: $bootstrap-column-padding;
        }
    }

    .floorplansButton,
    .devicesButton,
    .openingHoursButton {
        flex: 1 1 auto;
        @include media-breakpoint-down(sm) {
            margin-top: $spacer;
        }
    }
    .openingHoursGrid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        .openingHoursDayCentered {
            display: flex;
            align-self: center;
        }
    }

    .openingHoursLabel {
        padding-right: unset;
    }
    .hidden {
        visibility: hidden;
    }

    .loadingSpinnerContainer {
        display: flex;
        align-items: center;

        > div {
            margin-right: $spacer;
        }
    }

    .changeIndicatorContainer {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: $darker;
        min-height: 400px;
        .spinner {
            margin-bottom: $spacer;
        }
    }
}

.search {
    width: 99%;
    margin-bottom: $spacer / 3;
}

.placeholder {
    padding: $spacer-lg;
    min-height: $placeholder-box;
}
