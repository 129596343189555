@import '../../../variables';

.positiveIcon,
.negativeIcon,
.neutralIcon {
    margin-right: $spacer * 2;
}

.positiveIcon {
    color: $emotions-positive;
}

.negativeIcon {
    color: $emotions-negative;
}

.neutralIcon {
    color: $emotions-neutral;
}

.scoreChartColumn {
    height: 180px;
}

.emptySummaryRow {
    justify-content: space-between;
}

.greyLabel {
    color: $darker;
}

.maxEmotionScoreLabel {
    position: relative;
    top: $emotionScoreLabelTop;
    left: $emotionScoreLabelLeft;
    opacity: 0.7;
    width: fit-content;
    font-size: $font-size-smaller;
}
