@import '../../mixins';

.heatmapCard {
    height: $heatmap-carousel-height !important;
}

.histogramContainer {
    @include flexContainer($justifyContent: flex-start);
    overflow-x: auto;
    @include media-breakpoint-down(lg) {
        justify-content: center;
    }
}
