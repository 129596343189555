@import '../../../mixins';

.column {
    margin-top: $spacer;

    &.actionButtonsContainer {
        > button {
            margin-right: $spacer;
        }
        @include media-breakpoint-down(sm) {
            display: flex;
            justify-content: space-between;
            gap: $spacer;
            > button {
                flex: 1 1 auto;
            }
        }
    }
}
