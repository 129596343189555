@import '../../mixins';

.allLocalitiesButtonColumn {
    display: flex;
    flex-direction: column;

    button > {
        flex: 1 1 auto;
    }

    @include media-breakpoint-down(sm) {
        margin-top: $spacer;
        width: 100%;
    }
}

.label {
    @include media-breakpoint-down(sm) {
        margin-bottom: unset;
    }
}

.input {
    max-width: 290px;
    @include media-breakpoint-down(sm) {
        max-width: unset;
    }
}

.responsiveInput {
    flex: 1;
    @include media-breakpoint-down(sm) {
        max-width: unset;
    }
}
