// Colors
$white: #fff;
$grey: #f8f8f8;

$body-bg: $grey !important;
$body-base: #373737;
$vividi-primary: #00c6c1;
$vividi-primary-dark: #00a8a4;
$vividi-primary-light: #b3eeec;

$vividi-green: #00f085;
$light: #b4bac6;
$plotHeading: #999999;
$darker: #6c757d;
$border: #c1d0df;
$light-border: #ebedf8;
$info: #575756;
$light-gray: #a9a9a9;
$white-smoke: #f1f1f1;
$positiveTrend: #73c831;
$negativeTrend: #dc3545;
$dropdown-header-color: #000000;
$dropdown-link-color: $darker;
$dropdown-link-hover-color: $vividi-primary-dark;
$dropdown-link-hover-bg: $vividi-primary-light;
$dropdown-link-active-color: $vividi-primary-dark;
$dropdown-link-active-bg: $vividi-primary-light;
$form-check-input-checked-bg-color: $vividi-primary;
$button-negative-bg: #f2c3c7;
$rbt-token-bg: #e0f8f7;
$explanation-text-grey: #818a91;
$floorplan-live-view-legend-female: #e68790;
$floorplan-live-view-legend-male: $darker;
$dark-grey: #000000b2;

$light-green: rgb(12, 238, 244);
$grey90: #e5e5e5;
$grey134: #868686;

$emotions-positive: #73c831;
$emotions-neutral: #e4e4e4;
$emotions-negative: #d93444;

$emotions-plot-positive: #b7dd95;
$emotions-plot-neutral: #e5e5e5;
$emotions-plot-negative: #f19a96;

// Describe Scene colors
$otherLine: #616161;
$entranceLine: #00f085;
$passageLine: #0000ff;
$posZone: #ffc107;
$queueZone: #ff00d5;
$seatZone: #0ceef4;
$stopZone: #dc3545;

$otherZone: #616161;
$entranceLineHover: #00c06a;
$passageLineHover: #0000cc;
$posZoneHover: #d29d00;
$queueZoneHover: #cc00aa;
$seatZoneHover: #09bfc4;
$stopZoneHover: #dc3545;

// Border colors
$card-border-color: $border;
$popover-border-color: $border;
$conversionChartDividerColor: rgba(0, 168, 164, 0.26);
$nav-tabs-border-color: transparent;

// Plots palette
$palette-white: $white;
$palette-blue: #4c8df1;
$palette-darkBlue: #53608e;
$palette-lightBlue: #81b5f5;
$palette-pink: #ff7db2;
$palette-grey: #f4f4f4;
$palette-mediumGrey: #cccccc;
$palette-green: #9ac261;
$palette-red: #ff7e70;
$palette-purple: #8884d8;
$palette-bounced-red: #e68790;

$queuePurpleFill: #c9b5c4;
$queuePurpleStroke: #966288;
$queueYellowFill: #ffe299;
$queueYellowStroke: #deb347;
$queueGreenFill: #d1cb94;
$queueGreenStroke: #a1a353;
$queueRedStroke: #e88172;

// Queue details
$queueDetailsWaitTime: #0bd5db;
$queueDetailsServeTime: #00d979;

$conversionPurpleFill: #c9b5c4;
$conversionPurpleStroke: #966288;
$conversionRedFill: #ffc6bd;
$conversionRedStroke: #e88172;
$conversionYellowFill: #ffe299;
$conversionYellowStroke: #deb347;
$conversionGreenFill: #d1cb94;
$conversionGreenStroke: #a1a353;
$transparent: transparent;

// Zone occupancy statistics indication colors
$red-indication: $palette-red;
$yellow-indication: #ffde39;
$green-indication: #99c261;
$gray-indication: $white;
$outage-indication: $light-border;

// Describe scene colors
$zone-border: #0000ff;
$zone-fill: white;
$line-incoming: $vividi-green;
$line-outgoing: #f00034;
$line-hover: $zone-border;

//longRange
$longRange: #b3eeec50;

// Buttons
$button-shadow-light: 0px 1px 2px #00000029;
$button-link-grey: #6c757d;
$button-link-disabled-grey: #6c757d66;

$btn-padding-y: 0.63rem;
$btn-padding-x: 1.3rem;
$btn-font-size: 0.9rem;
$badge-font-size: 85%;
$btn-white-space: null !default;

$btn-padding-y-sm: 0.375rem;
$btn-padding-x-sm: 0.75rem;
$btn-font-size-sm: $btn-font-size;

$btn-padding-y-lg: 0.85rem;
$btn-padding-x-lg: 2rem;
$btn-font-size-lg: 1rem;

// Buttons focus config
$input-btn-focus-width: 0;
$input-btn-focus-color: none;
$input-btn-focus-box-shadow: none;
$btn-active-box-shadow: none;

// Input focus config
$input-focus-width: 0.1rem;
$input-focus-box-shadow: 0 0 0 $input-focus-width $vividi-primary-light;
$input-focus-border-color: $vividi-primary-light;

// 2D Histogram
$histogram-2d-nullValue: $grey;
$histogram-2d-lowValue: #c2f2f0;
$histogram-2d-midLowValue: #b3e5e4;
$histogram-2d-midValue: #4dc2bf;
$histogram-2d-midHighValue: $vividi-primary-dark;
$histogram-2d-highValue: #007d78;

// Border radius
$border-radius: 3px;
$border-radius-lg: $border-radius;
$button-border-radius: $border-radius;
$card-border-radius: 4px;
$first-button-group: $border-radius 0px 0px $border-radius;
$last-button-group: 0px $border-radius $border-radius 0px;

// Border width
$popover-border-width: 0px;
$dropdown-border-width: 0px;
$popover-arrow-width: 0;
$popover-arrow-height: 0;
$card-border-width: 0;
$table-border-width: 2px;

// Box shadows
$enable-shadows: true;
$small-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
$container-shadow: 0px 0px 3px 0 rgba(0, 0, 0, 0.1);
$dropdown-box-shadow: $small-box-shadow;

// Fonts
$font-family-sans-serif: Lato, sans-serif;
$link-hover-decoration: auto;

// Font size
$font-size-larger: 1.25rem;
$font-size-conversion-rates: 1.8rem;
$font-size-jumbotron: 2.5rem;
$font-size-statistic-box-small: 2rem;
$font-size-statistic-box: 3rem;
$font-size-statistic-box-large: 3.5rem;
$font-size-challenges: 2rem;
$font-size-smaller: 0.9rem;
$font-size-small: 0.8rem;
$font-size-tiny: 0.7rem;
$font-size-data-display-box: 1.5rem;
$font-size-emotions-icon: 1.3rem;
$font-size-device-input: 1.2rem;

$h1-font-size: 2.4rem;
$h2-font-size: 2.1rem;
$h3-font-size: 1.4rem;

// Font weight
$font-weight-button: 500;
$font-weight-header: 300;

// Paddings, margins
$spacer-xs: 0.5rem;
$spacer-sm: 1rem;
$spacer-md: 1.5rem;
$spacer-lg: 2rem;
$dropdown-item-padding-x: $spacer-md;
$dropdown-item-padding-y: 0.5rem;
$dropdown-padding-y: 0;
$form-check-input-gutter: $spacer-md;
$form-check-input-margin-y: 0.2rem;
$nav-item-padding-x: 0.5rem;
$nav-item-padding-y: 0.3rem;
$card-spacer-x: $spacer-lg;
$card-spacer-y: $spacer-md;
$modal-header-padding-x: $spacer-md;
$modal-inner-padding: $spacer-md;

// Heights
$navbar-height: 80px;
$queue-plot-min-height: 250px;
$gender-age-plot-min-height: 240px;
$visitors-in-time-min-height: 350px;
$heatmap-carousel-height: 450px;
$conversion-plot-height: 500px;
$users-form-height: 80vh;
$users-form-height-mobile: 35vh;
$describe-scene-height: 60vh;
$staySafe-live-view-height: 800px;
$placeholder-box: 500px;
$map-devices-container-height: 600px;
$max-typeahead-height: 90px;

// Widths
$heatmap-carousel-max-width: 1200px;
$popover-wide-max-width: 400px;
$table-name-max-width: 300px;
$heatmap-thumbnail-width: 320px;
$button-icon-width: 41.75px;
$details-page-max-width: 1300px;
$label-container-max-width: 260px;
$picker-max-width: 320px;
$floorplan-detail-interval-picker-min-width: 200px;
$floorplan-input-max-width: 400px;
// Position
$emotionScoreLabelTop: 63%;
$emotionScoreLabelLeft: 50%;

// Transitions
$normal-transition: all 0.1s ease 0s;

// Bootstrap overrides
// Pagination
$pagination-border-width: 0;

$text-muted: rgba(0, 0, 0, 0.5);

$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    xxl: 1150px,
) !default;

$custom-control-indicator-checked-color: $vividi-primary;
$custom-control-indicator-bg: white;
$custom-control-indicator-checked-bg: white;
$custom-control-indicator-border-color: $light-gray;
$custom-control-indicator-checked-border-color: $vividi-primary;

$theme-colors: (
    'primary': $vividi-primary,
);

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/variables';

$caret-spacing: $spacer;
$popover-header-padding-y: $spacer / 2;
$popover-header-padding-x: $spacer;
$popover-body-padding-y: $spacer;
$popover-body-padding-x: $popover-header-padding-x;
$bootstrap-column-padding: 15px;
