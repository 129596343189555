@import '../../mixins';

.footer {
    position: absolute;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 3rem;
    width: 100%;
    border-top: 1px solid $grey90;
    color: $light-gray;
    font-size: $font-size-smaller;
    padding: 0 $spacer;

    span {
        padding-right: $spacer * 2;
    }
}
