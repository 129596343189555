@import '../../variables';

.wrapper {
    :global .card-header {
        padding: $spacer 1.5 * $spacer;
    }

    :global .card-body {
        padding: $spacer 1.5 * $spacer;
    }

    @include box-shadow($small-box-shadow);
}

.outage {
    margin-top: $spacer;
    font-size: $font-size-sm;
    color: $text-muted;

    .icon {
        margin-right: $spacer / 2;
    }
}
