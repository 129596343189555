@import '../../mixins';

.heatmapCard {
    height: $heatmap-carousel-height !important;
}

.emotionHighlightCard {
    @include media-breakpoint-up(lg) {
        height: $heatmap-carousel-height !important;
    }
}

.scoreChartColumn {
    height: 270px;

    .maxEmotionScoreLabel {
        position: relative;
        top: $emotionScoreLabelTop;
        left: $emotionScoreLabelLeft;
        opacity: 0.7;
        width: fit-content;
        font-size: $font-size-smaller;
    }
}

.emotionsStatisticsBox {
    align-items: flex-start;

    > span {
        @include flexContainer($justifyContent: center);
    }

    > div {
        line-height: 4rem;
    }
}

.plot {
    @include plot;
}

.emotionsDistributionPlot {
    min-height: $visitors-in-time-min-height;
    .positivePlotIcon {
        color: $emotions-plot-positive;
        margin-left: $spacer * 0.5;
    }
    .negativePlotIcon {
        color: $emotions-plot-negative;
        margin-left: $spacer * 0.5;
    }
    .neutralPlotIcon {
        color: $emotions-plot-neutral;
        margin-left: $spacer * 0.5;
    }
}

.emotionsTableCell {
    font-weight: $font-weight-button;

    .negativeIcon,
    .positiveIcon,
    .neutralIcon {
        font-size: $font-size-emotions-icon;
    }

    .emotionsCellExplanatoryString {
        font-weight: 400;
        margin-left: $spacer;
        color: $darker;
    }
}

.emotionsStatisticsBoxItem {
    font-size: $font-size-jumbotron;
    color: $darker;
}

.emotionHighlightBox {
    margin-top: unset;

    // Override data display container
    > div {
        margin-top: 0;
    }
}

.customBoxItem {
    font-size: 1.3rem;
    display: flex;
    align-items: center;
    align-self: center;
    margin-bottom: $spacer;

    .emotionScoreTitle {
        font-size: $font-size-statistic-box-small;
        font-weight: 600;
    }

    .emotionOutlierDayTitle {
        font-size: $spacer * 1.5;
        white-space: nowrap;
    }
}

.highlightsColumn {
    padding: unset;
}

.emotionMaxScore {
    font-size: $font-size-small;
    margin-right: $spacer;
    margin-left: $spacer / 2;
    color: $light-gray;
    white-space: nowrap;
}

.emotionIcon {
    font-size: $font-size-statistic-box-small;
    margin-right: $spacer * 0.5;
}

.emotionIconSmall {
    font-size: $font-size-emotions-icon;
    margin-right: $spacer / 2;
}

.positiveIcon {
    @extend .emotionIcon;
    color: $emotions-positive;
}

.negativeIcon {
    @extend .emotionIcon;
    color: $emotions-negative;
}

.neutralIcon {
    @extend .emotionIcon;
    color: $emotions-neutral;
}

.emotionsCarouselHeader {
    @include flexContainer;
    .highlightedValue {
        direction: ltr;
    }
    .maxValueIndicator {
        font-size: $font-size-smaller;
    }
    font-weight: $font-weight-button;
}

.emotionsLegend {
    margin-top: $spacer;

    @include flexContainer($alignItems: center, $justifyContent: center);

    .positiveIcon {
        color: $emotions-plot-positive;
        margin-right: $spacer;
    }
    .neutralIcon {
        color: $emotions-plot-neutral;
        margin-right: $spacer;
    }
    .negativeIcon {
        color: $emotions-plot-negative;
        margin-right: $spacer;
    }

    .emotionsLegendItem {
        @include flexContainer($alignItems: center);
    }

    .leftItem {
        @extend .emotionsLegendItem;
        justify-content: flex-end;
    }
    .centerItem {
        @extend .emotionsLegendItem;
        justify-content: center;
        max-width: 200px;
    }
    .rightItem {
        @extend .emotionsLegendItem;
        justify-content: flex-start;
    }
}
