@import '../variables.scss';

.menuItemWrapper {
    border-bottom: $border-width solid $light;
    padding-bottom: 0.5rem;
}

.menuItem {
    display: flex;
    justify-content: space-between;
}

.optionId {
    color: $light;
    font-size: $font-size-small;
}

.optionLabel {
    padding: 0;
    margin: 0;
    white-space: hidden;
    overflow: hidden;
    text-overflow: ellipsis;
}
