@import '../../../mixins.scss';

.heatMapRowContainer {
    padding: $spacer $spacer $spacer * 2;
}

.intensityImageContainer {
    @include flexContainer($justifyContent: center);
    margin-top: $spacer * 2;
    margin-bottom: $spacer;
}

.mapToFloorplanContainer {
    @include flexContainer($justifyContent: center);
}

.heatMapRowRadioContainer {
    @include flexContainer($width: auto);
    margin: $spacer * 2 $spacer * 5;
    margin-top: 0;
}

.heatmapButtonsContainer {
    @include flexContainer($width: auto, $alignItems: flex-start);
    gap: 1rem;
}
