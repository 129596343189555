@import '../../mixins';

.imageModal {
    :global .modal-content {
        width: fit-content;
    }
    @include modalImage;
}

.thumbnail {
    cursor: pointer;
}
