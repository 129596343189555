@import '../../mixins';

.content {
    :global .card,
    :global .card-body {
        height: 100%;
    }

    :global .row {
        margin-bottom: 1.5 * $spacer;

        > * {
            padding-left: 0.75 * $spacer;
            padding-right: 0.75 * $spacer;
        }
    }
}

.floorplanHeatmapDetailDatePicker {
    @include flexContainer;
    gap: $spacer / 2;

    .navigationButtonHidden {
        opacity: 0;
    }
    .rangeDisplayButton {
        min-width: 200px;
        pointer-events: none;
    }
}

.toolbar {
    max-width: map-get($container-max-widths, 'xxl');
    margin: 0 auto;

    @supports (position: sticky) {
        position: sticky;
        top: 0;
        z-index: $zindex-sticky;
    }

    &.fourColumns {
        grid-template-columns: min-content min-content min-content minmax(0, 1fr);
    }

    &.threeColumns {
        grid-template-columns: min-content min-content minmax(0, 1fr);
    }

    display: grid;
    gap: $spacer;

    > *:last-child {
        text-align: right;
    }

    padding: 1.5 * $spacer;
    background: $grey;
    @include media-breakpoint-down(md) {
        @include flexContainer;
        padding-right: $spacer;
        padding-left: $spacer;
        justify-content: space-between;
        flex-direction: row;
        flex-wrap: wrap;
    }
}

.intensityImageContainer {
    @include flexContainer($justifyContent: center);
    margin-top: $spacer * 2;
    margin-bottom: $spacer * 2;
}

.alertContainer {
    @include flexContainer($justifyContent: flex-start);

    svg {
        margin-right: $spacer;
    }
}

.metricDescription {
    font-size: $font-size-smaller;
}
