@import '../../../variables.scss';

.setter {
    display: flex;
    flex-wrap: nowrap;
}

.currentOccupancyText {
    font-size: 1.4rem;
    margin-left: $spacer;
    margin-right: $spacer;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: $spacer * 3;
}

.currentOccupancySetterButton {
    height: 2 * $spacer;
    width: 2 * $spacer;
}
