@import '../../../mixins';

.usersEditDialog {
    :global .modal-content {
        border: unset;
        border-radius: $border-radius;
    }
    .header {
        background: $grey;
    }
}

.usersEditForm {
    .row {
        margin-bottom: $spacer * 2;
        align-items: center;
    }

    .label {
        @include media-breakpoint-down(sm) {
            margin-bottom: unset;
        }
    }

    .input {
        max-width: 290px;
        @include media-breakpoint-down(sm) {
            max-width: unset;
        }
    }

    .emailRow {
        @extend .row;
        @include media-breakpoint-down(sm) {
            display: none;
        }
    }

    .actionsRow {
        @extend .row;
        @include media-breakpoint-down(sm) {
            margin-bottom: unset;
        }
        margin-bottom: $spacer;
    }

    .actionsColumn {
        display: flex;
        gap: $spacer;
        flex-wrap: wrap;

        @include media-breakpoint-up(md) {
            > button:not(:last-child) {
                flex: 1 1 auto;
            }
        }
        @include media-breakpoint-down(sm) {
            flex-direction: column;
        }
    }
    .allLocalitiesButtonColumn,
    .deleteUserButtonColumn {
        display: flex;
        flex-direction: column;

        button > {
            flex: 1 1 auto;
        }

        @include media-breakpoint-down(sm) {
            margin-top: $spacer;
        }
    }

    .saveChangesButtonColumn {
        flex-direction: column;
        display: flex;
        padding: unset;
        margin-bottom: $spacer;

        button > {
            flex: 1 1 auto;
        }
    }
}
