@import '../../mixins';

.toggleButtonContainer {
    white-space: nowrap;

    .toggleButtonLeft {
        box-shadow: unset;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
    .toggleButtonRight {
        box-shadow: unset;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
    .toggleButtonMiddle {
        box-shadow: unset;
        border-radius: 0;
    }
}
