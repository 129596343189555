@import '../mixins.scss';

.boundariesContainer {
    @include flexContainer(column, flex-start);
    .boundaryContainer {
        @include flexContainer;
    }

    margin-bottom: $spacer;

    &:last-child {
        margin-bottom: 0;
    }
}
