@import '../../mixins.scss';

$pulseCircleFrozenShadow: 0 0 0 4px rgba(0, 198, 193, 0.3);
$pulseCircleFrozenDisabledShadow: 0 0 0 4px rgba(169, 169, 169, 0.3);

.indicator {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;

    span {
        font-size: 1rem;
        color: var(--IndicatorColor, $vividi-primary-dark);
    }
}

.pulseCircle {
    margin-right: $spacer;
    position: relative;
    width: 10px;
    height: 10px;

    &:before {
        content: '';
        position: relative;
        display: block;
        width: 300%;
        height: 300%;
        box-sizing: border-box;
        margin-left: -100%;
        margin-top: -100%;
        border-radius: 45px;
        background-color: var(--PulseCircleBeforeColor, $vividi-primary-light);
        animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
    }

    &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        display: block;
        width: 100%;
        height: 100%;
        background-color: var(--PulseCircleAfterColor, $vividi-primary-dark);
        border-radius: 15px;
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
        animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
    }
}

.pulseCircleFrozen {
    width: 10px;
    height: 10px;
    content: '';
    box-sizing: border-box;
    display: block;
    background-color: $vividi-primary;
    border-radius: 45px;
    box-shadow: $pulseCircleFrozenShadow;
}

.pulseCircleDisabled {
    @extend .pulseCircleFrozen;
    background-color: $light-gray;
    box-shadow: $pulseCircleFrozenDisabledShadow;
}

@keyframes pulse-ring {
    0% {
        transform: scale(0.33);
    }
    80%,
    100% {
        opacity: 0;
    }
}
@keyframes pulse-dot {
    0% {
        transform: scale(0.8);
    }
    50% {
        transform: scale(1);
    }
    100% {
        transform: scale(0.8);
    }
}
