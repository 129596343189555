@import '../../variables';

dl.tooltipTable {
    display: grid;
    grid-column-gap: 2 * $spacer;
    grid-template-columns: auto min-content;
    grid-auto-rows: 1fr;
    grid-auto-flow: row;
    margin: 0;
    white-space: nowrap;

    dd,
    dt {
        font-weight: normal;
    }

    dd {
        text-align: right;
    }

    .total {
        font-weight: bold;
    }
}
