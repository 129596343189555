@import '../../variables.scss';

.sectionSubheading {
    margin-top: $spacer * 2;
    margin-bottom: $spacer * 2;

    a {
        margin-left: $spacer * 2;
    }
}
