@import '../../mixins.scss';

.devicesListGroupItem {
    @include button($black, $background-color: $grey90, $hover-background-color: $vividi-primary);
    width: 100%;
    margin-bottom: $spacer * 0.5;

    :global .fa-fw {
        margin-right: $spacer;
    }

    @include text-truncate();
}

.devicesListGroupItemContainer {
    display: flex;
    flex-direction: row;
}
