@import '../../mixins.scss';

.editor {
    display: grid;
    overflow: hidden;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;

    * {
        grid-column: 1;
        grid-row: 1;
    }
}

.draggingLid {
    fill-opacity: 0;
    height: 100%;
    width: 100%;

    &:hover {
        cursor: move;
    }
}
