@import '../variables.scss';

.deviceIcon {
    color: black;
    margin-right: $spacer;

    &.white {
        color: white;
        stroke: black;
        stroke-width: 30px;
    }
}
