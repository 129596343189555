@import './variables';

@mixin flexContainer($direction: row, $alignItems: center, $justifyContent: space-between, $width: 100%, $gap: none) {
    display: flex;
    flex-direction: $direction;
    justify-content: $justifyContent;
    align-items: $alignItems;
    width: $width;
    gap: $gap;
}

@mixin histogramSegment($background: white) {
    background: $background;
    &:hover,
    &:active {
        background: $background;
    }
}

@mixin minimalScrollbar() {
    &::-webkit-scrollbar {
        border-radius: $spacer;
        width: $spacer * 0.4;
    }

    &::-webkit-scrollbar-thumb {
        background: $darker;
        border-radius: 10px;
    }

    &::-webkit-scrollbar-track {
        border-radius: 10px;
        background: $grey90;
    }
}

@mixin button-size($button-padding) {
    padding: $button-padding;
}

@mixin button(
    $color: $white,
    $background-color: $vividi-primary,
    $hover-color: white,
    $hover-background-color: $vividi-primary-dark,
    $font-size: $font-size-smaller,
    $shadow: none,
    $indent-icon: true
) {
    outline: none;
    color: $color;
    font-weight: $font-weight-button;
    background-color: $background-color;
    box-shadow: $shadow;
    border-radius: $border-radius;
    border: none;
    width: auto;
    height: fit-content;
    white-space: nowrap;
    transition: $normal-transition;

    svg {
        max-height: $spacer;
        max-width: $spacer;
        @if $indent-icon {
            margin-right: $spacer;
        }
    }

    &:hover {
        color: $hover-color;
        background-color: $hover-background-color;
    }

    &:focus {
        outline: none;
    }
}

@mixin secondaryButton() {
    @include button(black, $grey90, $vividi-primary-dark, $vividi-primary-light);
}

@mixin hideOnSmallerThan($screenSize: md) {
    @include media-breakpoint-down($screenSize) {
        display: none;
    }
}

@mixin plotContainer {
    min-height: 12.5rem;
    position: relative;

    @include media-breakpoint-up(md) {
        min-height: 14rem;
    }

    @include media-breakpoint-up(lg) {
        min-height: 15rem;
    }
}

@mixin plot {
    min-height: 500px;

    :global .card-title {
        margin-bottom: $spacer;
    }

    display: grid;
    grid-template-rows: min-content min-content minmax(0, 1fr);
}

@mixin fadeOut {
    animation: fadeOut 3s;
    @keyframes fadeOut {
        0% {
            opacity: 1;
        }
        16% {
            opacity: 1;
        }
        84% {
            opacity: 0;
        }
        100% {
            opacity: 0;
        }
    }
}

@mixin fadeIn {
    animation: fadeIn 3s;
    @keyframes fadeIn {
        0% {
            opacity: 0;
        }
        16% {
            opacity: 1;
        }
        84% {
            opacity: 1;
        }
        100% {
            opacity: 1;
        }
    }
}

@mixin modalImage {
    max-width: 85vw;
    display: flex;
    justify-content: center;
}

@mixin link {
    text-decoration: underline;
    color: $black;

    &:hover {
        text-decoration: underline;
        color: $vividi-primary-dark;
    }
}

// COMMON STYLES USED ACROSS THE APP
.controlButtonRow {
    @include flexContainer($justifyContent: flex-end);
    gap: $spacer;
}

.createButtonContainer {
    @include flexContainer();
    gap: $spacer;

    @include media-breakpoint-down(sm) {
        flex-direction: column;
        button,
        a {
            width: 100% !important;
        }
    }
}

.noItemsCreatedContainer {
    .noItemsCreated {
        color: $light-gray;
        font-size: $font-size-larger;
    }
}

.loadingContainer {
    @include flexContainer($justifyContent: center);
    aspect-ratio: 4/3;
    font-size: $font-size-data-display-box;
    color: $darker;
    flex-direction: column;
    background: $grey90;

    svg {
        margin-right: $spacer;
        font-size: 2rem;
    }
    .infoText {
        padding: $spacer * 1.5;
        font-size: $btn-font-size;
    }

    @include media-breakpoint-between(sm, md) {
        font-size: $font-size-device-input;
        svg {
            margin-right: $spacer;
            font-size: 1.5rem;
        }
    }
}
