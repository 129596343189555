@import '../../variables.scss';

.wrapper {
    display: flex;
    flex-wrap: nowrap;
    flex-flow: row;
    z-index: $zindex-sticky;

    .column {
        flex: 1;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-flow: column;
        padding-right: $spacer * 0.5;

        &:last-of-type {
            border-right: none;
        }

        .label {
            color: $darker;
            font-size: $font-size-small;
            text-align: right;
            white-space: nowrap;
            padding-bottom: $spacer;

            @include media-breakpoint-up(lg) {
                font-size: $spacer;
                padding-right: $spacer * 0.2;
            }
        }

        .dataDisplay {
            font-size: $font-size-data-display-box;
            display: flex;
            align-items: center;
            line-height: $spacer * 3.5;

            @include media-breakpoint-up(md) {
                font-size: $font-size-challenges;
            }

            @include media-breakpoint-up(lg) {
                font-size: $font-size-statistic-box-large;
            }
            .data {
                color: $vividi-primary;
                font-weight: 500;
                padding-left: $spacer * 0.5;

                @include media-breakpoint-up(lg) {
                    padding-left: $spacer;
                }
            }
        }

        .changeContainer {
            display: flex;
            align-items: center;
            line-height: $spacer * 2;
            padding-top: $spacer * 0.5;
        }
    }
}
