@import './variables.scss';
@import '~bootstrap/scss/bootstrap';
@import '~bootstrap-daterangepicker/daterangepicker';
@import './fonts';
@import './overrides';

html,
body {
    min-height: 100vh;
    min-height: -webkit-fill-available;
}

p {
    color: $info;
}

.img-fluid {
    max-width: 100%;
    min-height: 1px;
}

ul {
    list-style: square;
    color: $vividi-primary;
}

h1,
h2,
h3,
h4,
h5 {
    font-weight: 400;
}

.container hr {
    width: 100%;
    color: $grey90;
    border-top: 1px solid $grey90;
    padding-bottom: unset;
    margin-bottom: $spacer;
}

tspan,
.recharts-tooltip-wrapper {
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
}

.recharts-tooltip-wrapper {
    z-index: $zindex-tooltip;
}

.screen-subheading {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: $spacer;

    h3,
    h2 {
        font-weight: $display4-weight;
        margin-bottom: 0;
        line-height: 1;
    }
}

.btn[disabled] {
    cursor: auto;
}

// polyfill for react-scale-text lib to prevent the page from becoming crazy wide
.scaletext-wrapper {
    left: -100vw !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}
