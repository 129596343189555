@import '../../variables';

.intervalPicker {
    .navigationButtonHidden {
        opacity: 0;
    }

    button,
    input[type='button'] {
        margin-left: $spacer / 2;
    }

    .rangeDisplayButton {
        min-width: 200px;
        font-weight: bold;
    }
}
