@import '../../variables';

.toastWrapper {
    position: fixed;
    right: 1rem;
    top: $navbar-height + 15px;
    min-height: 8rem;
    z-index: $zindex-popover + 1;
}

.toast {
    background-color: transparent;
    border: none;
    box-shadow: none;
    margin-bottom: $spacer;
}

.alert {
    margin-bottom: 0;
}
