@import '../../mixins.scss';

.notConfiguredLocalitiesContainer {
    @include text-truncate;
    color: $text-muted;
    text-align: end;
    margin-top: $spacer;

    &.nothingConfiguredContainer {
        text-align: center;
        padding: 5rem 0;
    }
}
